import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";

import { useGoogleLogin } from "@react-oauth/google";

export function RegisterBusinessType() {
  const navigate = useNavigate();

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      fetch(
        "https://www.googleapis.com/oauth2/v3/userinfo?access_token=" +
          tokenResponse.access_token
      )
        .then((response) => response.json())
        .then((result) => {
          navigate(`/register/gmail`, {
            state: { google_id: result.sub, email: result.email },
          });
        })
        .catch((error) => console.log("error", error));
    },
  });

  return (
    <div className="">
      <div className="m-0 w-100 p-0 bg-white ">
        <Row className="g-0 h-100vh">
          <Col xl={5} lg={6} className="d-none text-center d-lg-block">
            <Image src="/images/cover_login.png" className="img-fit " />
          </Col>
          <Col xl={7} lg={6} className="d-flex flex-column py-4">
            <div className="mb-auto"></div>
            <Card className="card-login-st-1 mb-auto">
              <Card.Body>
                <div className="text-center">
                  <Image src="/logo.png" width={150} className="mb-4 mx-auto" />
                </div>
                <div className="text-center fs-18 fw-5 mb-4">สมัครสมาชิก</div>

                <div className="py-4">
                  <Link
                    to="/register/email"
                    className="btn border w-full text-left !text-[14px] mb-4"
                  >
                    <EmailOutlinedIcon className="!text-[34px] mr-[16px]" />{" "}
                    สมัครสมาชิกผ่าน Email
                  </Link>

                  <Button
                    type="button"
                    className="btn-login-google border  !text-[14px] shadow-none mb-5 w-100"
                    onClick={() => login()}
                  >
                    สมัครสมาชิกผ่าน Gmail
                  </Button>

                  <div className="text-center mb-3">
                    <span className="tc-1 fw-5">คุณมีบัญชีอยู่แล้วใช่ไหม </span>
                    <Link to="/login" className="text-[#2DC76D] fw-5">
                      เข้าสู่ระบบ
                    </Link>
                  </div>

                  <div className="text-center">
                    <Link to="/login" className="text-[#2F485] fw-5">
                      กลับหน้าหลัก
                    </Link>
                  </div>
                </div>
              </Card.Body>
            </Card>
            <div className="text-center fs-20 tc-3 d-flex align-items-end justify-content-center">
              <span className="me-3 ">Power By</span>
              <div className="pb-05">
                <Image src="/images/logo_allder.png" width={98} />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
