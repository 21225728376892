import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";

import { LoginBusiness } from "./pages/user-business-group/login";
import { RegisterBusinessGmail } from "./pages/user-business-group/registerBusinessGmail";
import { RegisterBusinessEmail } from "./pages/user-business-group/registerBusinessEmail";
import { RegisterBusinessType } from "./pages/user-business-group/registerBusinessType";
import { RegisterBusinessVerify } from "./pages/user-business-group/registerBusinessVerify";
import { RegisterBusinessConfirmEmail } from "./pages/user-business-group/registerBusinessConfirmEmail";

import { Business } from "./pages/business/business";
import { BusinessFormBasic } from "./pages/business/businessFormBasic";
import { BusinessFormGeneral } from "./pages/business/businessFormGeneral";
import { BusinessInfo } from "./pages/business/businesInfo";

import { Layout } from "./layouts/layout";
import { BusinessSetting } from "./pages/setting/business";
import { Productunit } from "./pages/setting/productunit";
import { ProductSaleUnit } from "./pages/setting/ProductSaleUnit";
import { SettingNumber } from "./pages/setting/document";

function App() {
  const [selectbranchID, setSelectBranchID] = useState(1);
  return (
    <Routes>
      <Route path="/" element={<LoginBusiness />} />
      <Route path="/login" element={<LoginBusiness />} />
      <Route path="/register" element={<RegisterBusinessType />} />
      <Route path="/register/gmail" element={<RegisterBusinessGmail />} />
      <Route path="/register/email" element={<RegisterBusinessEmail />} />
      <Route path="/register/verify" element={<RegisterBusinessVerify />} />
      <Route
        path="/register/confirm/email"
        element={<RegisterBusinessConfirmEmail />}
      />

      <Route
        element={
          <ProtectedRoute>
            <Routes>
              <Route path="/business" element={<Business />} />
              <Route path="/business/create" element={<BusinessFormBasic />} />
              <Route
                path="/business/create/info"
                element={<BusinessFormGeneral />}
              />
              <Route path="/business/info/:id" element={<BusinessInfo />} />
            </Routes>
          </ProtectedRoute>
        }
      >
        <Route path="/business/*" />
      </Route>

      <Route
        path="/:shopSlug"
        element={
          <Layout
            setSelectBranchID={setSelectBranchID}
            selectbranchID={selectbranchID}
          />
        }
      >
        {/* Protected Settings Routes */}
        <Route
          element={
            <ProtectedRoute>
              <Routes>
                <Route path="setting/business/:id" element={<BusinessSetting />} />
                <Route path="setting/product/unit" element={<Productunit />} />
                <Route path="setting/product/saleunit" element={<ProductSaleUnit />} />
                <Route path="setting/document" element={<SettingNumber />} />
              </Routes>
            </ProtectedRoute>
          }
        >
          <Route path="setting/*" />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;