import { combineReducers } from "@reduxjs/toolkit";
import common from "./common.slice";
import master from "./master.slice";
import userBusinessGroupReducer from "./userBusinessGroup.slice";
import business from "./business.slice";

const appReducer = combineReducers({
  common,
  master,
  userBusinessGroupReducer,
  business,
});

const rootReducer = (state, action) => {
  if (action.type === "RESET_STATE") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
