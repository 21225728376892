import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: null,
  user: null
};

const slice = createSlice({
  name: "userBusiness",
  initialState,
  reducers: {
    resetState: () => initialState,
    setUser: (state, action) => {
      state.user = action.payload;
    }
  }
});

export const { success, resetState, setUser } = slice.actions;
export default slice.reducer;