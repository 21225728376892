import React, { useState } from 'react';
import Select from 'react-select';
    
export function  Navcustom({selectbranchID,setSelectBranchID}) {
    function handleSidebar(e) {
        var sidebar = document.getElementsByClassName('app');
        for (var i = 0; i < sidebar.length; i++) {
            sidebar[i].classList.toggle('sidenav-toggled')
        }
    }

    const optionsBranch= [
        { value: 1, label: 'สาขา สำนักงานใหญ่' },
        { value: 2, label: 'สาขา ย่อย' },
    ] 
    return (
        <nav className="navbar fixed-top navbar-st-1">
            <div className="container-fluid">
                <div className="d-flex align-items-center">
                    <div className="app-sidebar__toggle ms-0 me-4" data-toggle="sidebar" onClick={handleSidebar} >
                        <a className="open-toggle" href="#"></a>
                        <a className="close-toggle" href="#"></a>
                    </div>
                    <div className="d-lg-block d-none">

                        <Select as="select"
                            name="branch"
                            className="w-100  select-st-2 "
                            options={optionsBranch}
                            placeholder="เลือกสาขา"
                            onChange={(e) => {
                                setSelectBranchID(e.value)
                            }}
                            value={
                                optionsBranch.filter(
                                    item => item.value === selectbranchID
                                )
                            }
                        />

                    </div>
                </div>

                <div className="d-flex align-items-center">
                    <div className="dropdown nav-item main-header-message me-xl-3 me-2">
                        <a className="new nav-link" href="#" data-bs-toggle="dropdown">
                            <div className="ih-2 p-4"></div>
                        </a>
                        <div className="dropdown-menu">

                        </div>
                    </div>
                    <div className="dropdown nav-item main-header-notification me-xl-3 me-2">
                        <a className="new nav-link" href="#" data-bs-toggle="dropdown"><div className="ih-1 p-4"></div></a>
                        <div className="dropdown-menu">

                        </div>
                    </div>
                    <div className="nav-item full-screen fullscreen-button me-xl-3 me-2">
                        <a className="new nav-link full-screen-link" href="#"><div className="ih-3 p-4"></div></a>
                    </div>

                    <div className="dropdown gg_menu nav-item main-header-notification me-xl-3 me-2">
                        <a className="new nav-link" href="#" data-bs-toggle="dropdown"> <div className="ih-4 p-4"></div></a>
                        <div className="dropdown-menu">

                        </div>
                    </div>
                    <div className="dropdown me-xl-5">
                    <a className="btn  dropdown-toggle-1 drop-left " href="#" role="button" id="" data-bs-toggle="dropdown" aria-expanded="false">
                        <div className="d-flex align-items-center">
                            <img src="/images/Avatar.png" className="Avatar" />
                        </div>
                    </a>
                    <ul className="dropdown-menu" >
                        <li><a className="dropdown-item text-color-6 fs-14 spacing-1 ms-2" href="#">ออกจากระบบ</a></li>
                    </ul>
                    </div>
                </div>


            </div>
        </nav>
    );
}