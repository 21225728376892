import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useDispatch, useSelector } from "react-redux";
import { businessHandler } from "../../redux/handlers";

export function BusinessInfo() {
  const { id } = useParams();
  const [business, setBusiness] = useState();
  const [branch, setBranch] = useState();
  const dispatch = useDispatch();
  const selectedBusiness = useSelector(
    (state) => state.business?.selectedBusiness
  );

  useEffect(() => {
    if (id) {
      dispatch(businessHandler.getBusinessById(id));
    }
  }, [dispatch, id]);

  const businessinfo = {
    id: 1,
    image: "/images/business/logo1.png",
    name: "ชื่อกิจการ  :  อินเตอร์ คอฟฟี่ เทรด",
    branch: [
      {
        branchID: 1,
        branchCode: "00001",
        branchName: "โรงคั่วกาแฟพะเยา",
        branchImage: "/images/business/logo1.png",
        pos_url: "https://pos.alldercafe.com/PhayaoCoffeeRoaster/B01/login",
        staff_url: false,
      },
      {
        branchID: 2,
        branchCode: "00002",
        branchName: "โรงคั่วกาแฟพะเยา",
        branchImage: "/images/business/logo1.png",
        pos_url: "https://pos.alldercafe.com/PhayaoCoffeeRoaster/B01/login",
        staff_url: "https://staff.alldercafe.com/PhayaoCoffeeRoaster/B01/login",
      },
      {
        branchID: 3,
        branchCode: "00003",
        branchName: "โรงคั่วกาแฟพะเยา",
        branchImage: "/images/business/logo1.png",
        pos_url: "https://pos.alldercafe.com/PhayaoCoffeeRoaster/B01/login",
        staff_url: false,
      },
    ],
    backoffice_url: "https://bo.alldercafe.com/PhayaoCoffeeRoaster/login",
  };

  useEffect(() => {
    setBusiness(businessinfo);

    if (businessinfo.branch && businessinfo.branch.length > 0) {
      setBranch(businessinfo.branch[0]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="bg-[#FFF]">
      <Container className="">
        <div className="m-0 w-100 py-sm-2 bg-white h-100vh d-flex flex-column">
          <div className="mb-auto">
            <Link to="/business" className="btn btn-back-st-1 mb-auto">
              ย้อนกลับ
            </Link>
          </div>
          {selectedBusiness?.business ? (
            <div className="mb-auto ">
              <img
                src={selectedBusiness.business}
                className="mx-auto"
                alt="Business"
              />
              <div className="font-bold text-[20px] text-center mb-3">
                <span className="text-[#061126]">ชื่อกิจการ : </span>
                <span className="text-[#2DC76D]">
                  {selectedBusiness.business?.entityType?.code == "NATURAL"
                    ? `${selectedBusiness.business.first_name} ${selectedBusiness.business.last_name}`
                    : selectedBusiness.business.compony_name}
                </span>
              </div>
              <div className="text-center text-[#4C4E64]/[0.87] mb-4">
                ท่านสามารถจัดการร้านค้าของท่านได้ตามลิงค์ดังนี้
              </div>

              <div className="flex items-center  mb-4 max-w-[650px] mx-auto ">
                <div>Backoffice</div>
                <div className="rounded-lg px-3 py-2   overflow-hidden  bg-[#EAF9F0] text-[#2DC76D] lg:mx-4 mx-2 glow">
                  <div className="flex">
                    <InsertLinkIcon className="mr-3" />{" "}
                    {process.env.REACT_APP_URL}/{selectedBusiness.business.slug}
                  </div>
                </div>
                <CopyToClipboard
                  text={`${process.env.REACT_APP_URL}/{selectedBusiness.business.slug}`}
                >
                  <Button className="border-0 text-[#2DC76D] py-[1px] w-[60px] bg-[#FFF]">
                    <div>
                      <ContentCopyIcon className="text-[14px]" />
                    </div>
                    <div className="text-[10px] text-nowrap">คัดลอก</div>
                  </Button>
                </CopyToClipboard>
              </div>

              <div className="text-center mb-5">
                <Link
                  to={`${process.env.REACT_APP_URL}/${selectedBusiness.business.slug}/setting/business/${selectedBusiness.id}`}
                  className="bg-[#2DC76D] text-[#FFF] btn mx-3 hover:bg-[#2DC76D]"
                >
                  จัดการข้อมูลธุรกิจ
                </Link>
              </div>

              <div className="text-[#4C4E64]/[0.87] text-center text-lg mb-3">
                เลือกสาขา
              </div>
              <div className="flex justify-center flex-wrap mb-5">
                {/* {business &&
                  business.branch &&
                  business.branch.map((item, index) => (
                    <div
                      key={`inline-${index}`}
                      className={` ${
                        item.branchID === branch.branchID ? "bg-[#2DC76D]" : ""
                      } mb-3 flex items-center rounded shadow w-[243px] p-2 mx-3 h-[73px] cursor-pointer`}
                      onClick={() => {
                        setBranch(item);
                      }}
                    >
                      <img
                        src={item.branchImage}
                        className="w-[50px] h-[50px] rounded-full object-cover mr-2"
                        alt="branch"
                      />
                      <div
                        className={` ${
                          item.branchID === branch.branchID ? "text-[#FFF]" : ""
                        } `}
                      >
                        <div>{item.branchName}</div>
                        <div
                          className={` ${
                            item.branchID === branch.branchID
                              ? "text-[#FFF]"
                              : "text-[#3C4043]"
                          } text-sm`}
                        >
                          ({item.branchCode})
                        </div>
                      </div>
                    </div>
                  ))} */}
              </div>
              <div className="max-w-[650px] mx-auto">
                {branch ? (
                  <>
                    <div className="flex items-center  mb-4">
                      <div>POS</div>
                      <div className="rounded-lg px-3 py-2   overflow-hidden  bg-[#EAF9F0] text-[#2DC76D] mx-4 glow">
                        <div className="flex">
                          <InsertLinkIcon className="mr-3" />{" "}
                          {process.env.REACT_APP_URL}/
                          {selectedBusiness.business.slug}/login
                        </div>
                      </div>
                      <CopyToClipboard text={branch.pos_url}>
                        <Button className="border-0 text-[#2DC76D] py-[1px] bg-[#FFF]">
                          <div>
                            <ContentCopyIcon className="text-[14px]" />
                          </div>
                          <div className="text-[10px] text-nowrap">คัดลอก</div>
                        </Button>
                      </CopyToClipboard>
                    </div>

                    <div className="flex items-center  mb-4">
                      <div>STAFF</div>
                      <div
                        className={`rounded-lg px-3 py-2   overflow-hidden  bg-[#EAF9F0] ${
                          branch.staff_url ? "text-[#2DC76D]" : "text-[#000000]"
                        }  mx-4 glow`}
                      >
                        <div className="flex">
                          <InsertLinkIcon className="mr-3" />
                          <div className="text-nowrap">
                            {branch.staff_url
                              ? branch.staff_url
                              : "กรุณาเปิดใช้งานระบบ Staff ก่อน"}
                          </div>
                        </div>
                      </div>
                      {branch.staff_url ? (
                        <CopyToClipboard text={branch.staff_url}>
                          <Button className="border-0 text-[#2DC76D] py-[1px] bg-[#FFF]">
                            <div>
                              <ContentCopyIcon className="text-[14px]" />
                            </div>
                            <div className="text-[10px] text-nowrap">
                              คัดลอก
                            </div>
                          </Button>
                        </CopyToClipboard>
                      ) : (
                        ""
                      )}
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="text-center fs-20 pb-4 mt-5 tc-3 d-flex align-items-end justify-content-center">
            <span className="me-3 ">Power By</span>
            <div className="pb-05">
              <Image src="/images/logo_allder.png" width={98} />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
