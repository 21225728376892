import * as masterAction from "../slices/master.slice";
import { setLoading, setError } from "../slices/common.slice";
import { http } from "../../utils/http";
import { endPoint, OK } from "../../constants/server/api";

const createConfig = (endpoint, params = null) => {
  return {
    method: "get",
    url: `${endpoint}`,
    ...(params && { params }),
    headers: { "Content-Type": "application/json" },
  };
};

const handleError = (error, dispatch) => {
  dispatch(setError(error.message ?? error.response?.data?.message));
  throw error;
};

const fetchData = async (endpoint, params, dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await http(createConfig(endpoint, params));
    if (response?.status === 200) {
      return response.data.data;
    }
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setLoading(false));
  }
};

export const getAllPrefixName = (entityTypeCode) => async (dispatch) => {
  const data = await fetchData(
    "prefix-name/list",
    { entity_type_code: entityTypeCode },
    dispatch
  );
  if (data) {
    dispatch(masterAction.setPrefixNames(data));
  }
};

export const getShopProductCategories = () => async (dispatch) => {
  const data = await fetchData(
    "shop-product-category/list",
    null,
    dispatch
  );
  if (data) {
    dispatch(masterAction.setShopProductCategories(data));
  }
};

export const getRestaurantTypes = () => async (dispatch) => {
  const data = await fetchData("restaurant-type/list", null, dispatch);
  if (data) {
    dispatch(masterAction.setRestaurantTypes(data));
  }
};

export const getEntityTypes = () => async (dispatch) => {
  const data = await fetchData("entity-type/list", null, dispatch);
  if (data) {
    dispatch(masterAction.setEntityTypes(data));
  }
};
