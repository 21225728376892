import { setUser } from "../slices/userBusinessGroup.slice";
import { setLoading, setError } from "../slices/common.slice";
import { http } from "../../utils/http";
import { endPoint, OK } from "../../constants/server/api";

const createConfig = (method, endpoint, data = null) => {
  const token = localStorage.getItem("accessToken");
  return {
    method,
    url: `${endPoint.BO_URL}/user-business-group/${endpoint}`,
    ...(data && { data }),
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : undefined,
    },
  };
};

const handleError = (error, dispatch) => {
  dispatch(setError(error.message ?? error.response?.data?.message));
  throw error;
};

const fetchData = async (method, endpoint, data, dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await http(createConfig(method, endpoint, data));
    if (response?.status === 200 || response?.status === 201) {
      const { access_token, user } = response.data.data;
      if (access_token) {
        localStorage.setItem("accessToken", access_token);
        http.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${access_token}`;
      }
      return response.data.data;
    }
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setLoading(false));
  }
};

export const userBusinessLogin = (email, password) => async (dispatch) => {
  const data = await fetchData(
    "post",
    "login",
    { email, password },
    dispatch
  );
  if (data) {
    dispatch(setUser({ user: data.user }));
    return OK;
  }
};

export const userBusinessGoogleLogin =
  (googleId, email) => async (dispatch) => {
    const data = await fetchData(
      "post",
      "google-login",
      { googleId, email },
      dispatch
    );
    if (data) {
      dispatch(setUser({ user: data.user }));
      return OK;
    }
  };

export const userBusinessRegister = (registerData) => async (dispatch) => {
  const data = await fetchData(
    "post",
    "register",
    {
      prefix_name_id: parseInt(registerData.prefix_name_id),
      firstName: registerData.firstName,
      lastName: registerData.lastName,
      email: registerData.email,
      password: registerData.password,
      phoneNumber: registerData.phoneNumber,
    },
    dispatch
  );
  if (data) {
    dispatch(setUser({ user: data }));
    return OK;
  }
};

export const userBusinessGoogleRegister =
  (registerData) => async (dispatch) => {
    const data = await fetchData(
      "post",
      "google-register",
      {
        email: registerData.email,
        googleId: registerData.googleId,
        prefix_name_id: parseInt(registerData.prefix_name_id),
        firstName: registerData.firstName,
        lastName: registerData.lastName,
        phoneNumber: registerData.phoneNumber,
      },
      dispatch
    );
    if (data) {
      dispatch(setUser({ user: data }));
      return OK;
    }
  };

export const resendVerificationEmail = (email) => async (dispatch) => {
  await fetchData("get", `resend-verification/${email}`, null, dispatch);
  return OK;
};

export const verifyEmail = (verifyCode) => async (dispatch) => {
  await fetchData(
    "get",
    `verify-email?verifyCode=${verifyCode}`,
    null,
    dispatch
  );
  return OK;
};
