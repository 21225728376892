import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  prefixNames: null,
  shopProductCategories: null,
  restaurantTypes: null,
  entityTypes: null,
};

const masterSlice = createSlice({
  name: "master",
  initialState,
  reducers: {
    setPrefixNames: (state, action) => {
      state.prefixNames = action.payload;
    },
    setShopProductCategories: (state, action) => {
      state.shopProductCategories = action.payload;
    },
    setRestaurantTypes: (state, action) => {
      state.restaurantTypes = action.payload;
    },
    setEntityTypes: (state, action) => {
      state.entityTypes = action.payload;
    },
    resetState: () => initialState,
  },
});

export const {
  setPrefixNames,
  setShopProductCategories,
  setRestaurantTypes,
  setEntityTypes,
  resetState,
} = masterSlice.actions;

export default masterSlice.reducer;
