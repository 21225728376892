import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { businessHandler } from "../../redux/handlers";

export function Business() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const businesses = useSelector((state) => state.business?.businesses);
  const loading = useSelector((state) => state.loading?.loading);

  useEffect(() => {
    dispatch(businessHandler.getAllBusinesses());
  }, [dispatch]);

  return (
    <Container>
      <div className="m-0 w-100 py-sm-2 h-100vh d-flex flex-column">
        <div className="mb-auto">
          <Link to="/login" className="btn btn-back-st-1 mb-auto">
            กลับหน้า Login
          </Link>
        </div>
        <div className="mb-auto">
          {loading ? (
            <div className="text-center p-5">Loading...</div>
          ) : businesses && businesses.length > 0 ? (
            <Businesslist business={businesses} />
          ) : (
            <BusinessNone />
          )}
        </div>
        <div className="text-center fs-20 pb-4 tc-3 d-flex align-items-end justify-content-center">
          <span className="me-3">Power By</span>
          <div className="pb-05">
            <Image src="images/logo_allder.png" width={98} />
          </div>
        </div>
      </div>
    </Container>
  );
}

function Businesslist({ business }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.clear();
    dispatch({ type: "RESET_STATE" });
    navigate("/login");
  };

  return (
    <div>
      <div className="tc-1 fs-20 fw-6 mb-4 text-center">
        เลือกกิจการต้องการใช้งาน
      </div>
      {business.map((item) => (
        <Link
          to={`/business/info/${item.id}`}
          className="btn btn-select-business mx-auto mb-4"
          key={item.id}
        >
          <div className="business-logo">
            <Image src={item.image_path} />
          </div>
          <div>
            <div className="business-name mb-1">{item.name}</div>
            <div className="business-level">
              {item.entity_type_id == 1
                ? item.compony_name
                : `${item.first_name} ${item.last_name}`}
            </div>
          </div>
        </Link>
      ))}
      <Link to="/business/create" className="btn btn-add-business mx-auto mb-5">
        <div className="icon-plus"></div>
        <div>
          <div className="text-start tc-4 fw-bold">เพิ่มกิจการ</div>
          <div className="text-start fs-10">กดที่นี่เพิ่มอัปเกรดบัญชี</div>
        </div>
      </Link>
      <div className="text-center">
        <Button className="btn-st-3" onClick={handleLogout}>
          ออกจากระบบ
        </Button>
      </div>
    </div>
  );
}

function BusinessNone() {
  return (
    <div>
      <div className="text-center text-[24px] fw-bold tc-1">
        คุณยังไม่มีกิจการกรุณาสร้างกิจการก่อน
      </div>
      <div className="text-center py-lg-5 py-4 my-lg-5">
        <Image src="images/img1.png" className="mx-auto" width={498} />
      </div>
      <div className="text-center">
        <Link to="/business/create" className="btn btn-st-3 mx-auto mb-5">
          สร้างกิจการใหม่
        </Link>
      </div>
    </div>
  );
}

export default Business;
