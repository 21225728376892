import { setBusinesses, setSelectedBusiness } from "../slices/business.slice";
import { setLoading, setError } from "../slices/common.slice";
import { http } from "../../utils/http";
import { endPoint, OK } from "../../constants/server/api";

const createConfig = (method, endpoint, data = null) => {
  const token = localStorage.getItem("accessToken");
  return {
    method,
    url: `${endPoint.BO_URL}/business/${endpoint}`,
    ...(data && { data }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
};

const handleError = (error, dispatch) => {
  dispatch(setError(error.message ?? error.response?.data?.message));
  throw error;
};

const fetchData = async (method, endpoint, data, dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await http(createConfig(method, endpoint, data));

    if (response?.status === 200 || response?.status === 201) {
      return response.data.data;
    }
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setLoading(false));
  }
};

export const getAllBusinesses = () => async (dispatch) => {
  const data = await fetchData("get", "list", null, dispatch);
  if (data?.businesses) {
    dispatch(setBusinesses(data.businesses));
  }
};

export const getBusinessById = (businessId) => async (dispatch) => {
  const data = await fetchData("get", `${businessId}`, null, dispatch);
  if (data) {
    dispatch(setSelectedBusiness(data));
  }
};

export const createBusiness = (businessData) => async (dispatch) => {
  await fetchData("post", "create", businessData, dispatch);
  return OK;
};

export const updateBusiness =
  (businessId, businessData) => async (dispatch) => {
    await fetchData(
      "put",
      `update/${businessId}`,
      businessData,
      dispatch
    );
    return OK;
  };

export const deleteBusiness = (businessId) => async (dispatch) => {
  await fetchData("delete", `delete/${businessId}`, null, dispatch);
  return OK;
};
