export const NETWORK_CONNECTION_MESSAGE =
  "Cannot connect to server, Please try again.";
export const NETWORK_TIMEOUT_MESSAGE =
  "A network timeout has occurred, Please try again.";
export const UPLOAD_PHOTO_FAIL_MESSAGE =
  "An error has occurred. The photo was unable to upload.";
export const TIMEOUT_NETWORK = "ECONNABORTED";
export const NOT_CONNECT_NETWORK_CODE = 500;

export const ERROR_MESSAGES = {
  USER_NOT_FOUND: "ไม่พบบัญชีผู้ใช้นี้ในระบบ กรุณาตรวจสอบอีกครั้ง",
  IS_GOOGLE_ACCOUNT: "บัญชีนี้เป็นบัญชี Google กรุณาเข้าสู่ระบบด้วย Google",
  NOT_HAS_VERIFY_EMAIL: "กรุณายืนยันอีเมลก่อนเข้าใช้งาน",
  INVALID_PASSWORD: "รหัสผ่านไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง",
  EMAIL_ALREADY_EXIST: "อีเมลนี้ถูกใช้งานแล้ว กรุณาใช้อีเมลอื่น",
  EMAIL_HAS_ALREADY: "อีเมลนี้ถูกใช้งานแล้ว กรุณาใช้อีเมลอื่น",

  SLUG_ALREADY_USED: "เนื่องจาก slug นี้ถูกใช้งานแล้ว กรุณากรอกใหม่อีกครั้ง",
  BUSINESS_NOT_FOUND: "ไม่พบข้อมูลธุรกิจที่ค้นหา",

  DEFAULT: "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง",
  NETWORK_ERROR:
    "ไม่สามารถเชื่อมต่อกับระบบได้ กรุณาตรวจสอบการเชื่อมต่ออินเทอร์เน็ต",
  SERVER_ERROR: "เกิดข้อผิดพลาดจากระบบ กรุณาลองใหม่ภายหลัง",
};
