
import React, { useState,useEffect } from 'react';
import {Link,useNavigate} from "react-router-dom";

import Form from 'react-bootstrap/Form';
import Select, { StylesConfig } from 'react-select';
import { Formik } from 'formik';
import * as yup from 'yup';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import Button from 'react-bootstrap/Button';

export function  SettingNumber() {

    const [tab, setTab] = useState(1);

    
    return (
        <div>
            <nav  aria-label="breadcrumb" className="mt-2">
                <ol className="breadcrumb fs-16 ps-0">
                <li className="breadcrumb-item "><a href="#" className="tc-7  text-decoration-none">ตั้งค่า</a></li>
                <li className="breadcrumb-item "><a href="#" className="tc-7  text-decoration-none">ตั้งค่าทั่วไป</a></li>
                <li className="breadcrumb-item active tc-1 " aria-current="page"> ตั้งค่าเลขที่   </li>
                </ol>
            </nav>
            <div className="fs-24 tc-7  mb-4 lh-1">ตั้งค่าเลขที่ </div>
            <div className="card card-st-1">
                <div className="card-header">
                    <nav>
                        <div className="nav nav-tabs nav-tabs-st-2" id="nav-tab" role="tablist">
                        <button className={ tab == 1 ? ( 'active nav-link') : ('nav-link ') }   type="button"  onClick={ e => { setTab(1) } }>  ตั้งค่าเลขระบบ</button>
                        <button className={ tab == 2 ? ( 'active nav-link') : ('nav-link ') } type="button" onClick={ e => { setTab(2) } }> ตั้งค่าเลขเอกสาร</button>
                        </div>
                    </nav>
                </div>
                {
                    tab == 1 ? (
                        <NumberSystem />
                    ) : (
                        tab == 2 ? (
                            <NumberDocument />
                        ) : ''
                    )
                }
                
            </div>
        </div>
    )

}

const documentrunformatoption = [
    {
      value: 'นับใหม่ทุกปี',
      label: 'นับใหม่ทุกปี',
    },
    {
      value: 'นับใหม่ทุกเดือน',
      label: 'นับใหม่ทุกเดือน',
    },
    {
      value: 'นับใหม่ทุกวัน',
      label: 'นับใหม่ทุกวัน',
    },
    {
      value: 'ไม่นับเริ่มใหม่',
      label: 'ไม่นับเริ่มใหม่',
    },
];


const yearoption = [
    {
      value: '2024',
      label: 'เลขปี 4 ตำแหน่งในรูปแบบคริสต์ศักราช (2024)',
    },
    {
      value: '2567',
      label: 'เลขปี 4 ตำแหน่งในรูปแบบพุทธศุกราช (2567)',
    },
    {
      value: '24',
      label: 'เลขปี 2 ตำแหน่งในรูปแบบคริสต์ศักราช (24)',
    },
    {
      value: '67',
      label: 'เลขปี 2 ตำแหน่งในรูปแบบพุทธศุกราช (67)',
    },
    {
        value: 0,
        label: 'ไม่แสดง',
      }
];

const monthoption = [
    {
        value: 1,
        label: 'แสดงเดือน',
    },
    {
        value: 0,
        label: 'ไม่แสดง',
    },
];

const dateoption = [
    {
        value: 1,
        label: 'แสดงวัน',
    },
    {
        value: 0,
        label: 'ไม่แสดง',
    },
];

const digitoption = [
    {
        value: 2,
        label: 2,
    },
    {
        value: 3,
        label: 3,
    },
    {
        value: 4,
        label: 4,
    },
    {
        value: 5,
        label: 5,
    },
    {
        value: 6,
        label: 6,
    },
    {
        value: 7,
        label: 7,
    },
    {
        value: 8,
        label: 8,
    },
    {
        value: 9,
        label: 9,
    }
];

const NumberSystem = () => {

    const schema = yup.object().shape({})

    const [formdisabled, setFormdisabled] = useState(false);
    let newDate = new Date()

    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let currentnumber = 1

    Number.prototype.pad = function(n) {
        if (n==undefined)
            n = 2;
    
        return (new Array(n).join('0') + this).slice(-n);
    }


    const [form, setForm] = useState({
        "emp_runnumber_format":"",
        "emp_initial_characters":"",
        "emp_special_characters":"",
        "emp_year_format":"",
        "emp_month_format":"",
        "emp_date_format":"",
        "emp_digit_format":"",
        "member_runnumber_format":"",
        "member_initial_characters":"",
        "member_special_characters":"",
        "member_year_format":"",
        "member_month_format":"",
        "member_date_format":"",
        "member_digit_format":"",
        "admin_runnumber_format":"",
        "admin_initial_characters":"",
        "admin_special_characters":"",
        "admin_year_format":"",
        "admin_month_format":"",
        "admin_date_format":"",
        "admin_digit_format":"",
        "product_runnumber_format":"",
        "product_initial_characters":"",
        "product_special_characters":"",
        "product_year_format":"",
        "product_month_format":"",
        "product_date_format":"",
        "product_digit_format":""
    });
    

    useEffect(() => {
        setForm({
            "emp_runnumber_format":"นับใหม่ทุกปี",
            "emp_initial_characters":"EMP",
            "emp_special_characters":"-",
            "emp_year_format":"2024",
            "emp_month_format":1,
            "emp_date_format":1,
            "emp_digit_format":4,
            "member_runnumber_format":"นับใหม่ทุกปี",
            "member_initial_characters":"CM",
            "member_special_characters":"-",
            "member_year_format":"2024",
            "member_month_format":1,
            "member_date_format":1,
            "member_digit_format":4,
            "admin_runnumber_format":"นับใหม่ทุกปี",
            "admin_initial_characters":"AD",
            "admin_special_characters":"-",
            "admin_year_format":"2024",
            "admin_month_format":1,
            "admin_date_format":1,
            "admin_digit_format":4,
            "product_runnumber_format":"นับใหม่ทุกปี",
            "product_initial_characters":"P",
            "product_special_characters":"-",
            "product_year_format":"2024",
            "product_month_format":1,
            "product_date_format":1,
            "product_digit_format":4
        });
        setFormdisabled(true)
    
    }, []);


    return (
        <Formik
            enableReinitialize
            initialValues={ form }
            validationSchema={schema}
            onSubmit={async (values) => {
                setFormdisabled(true)
                
            }}
        >
            {({
                handleSubmit,
                handleChange,
                touched,
                errors,
                values,
                setFieldValue,
                isInvalid,
                isValid

            }) => (
            <Form noValidate onSubmit={handleSubmit}>
                <fieldset >
                    <div className="card-body px-xl-4">
                        <div>
                            <div  className='mb-4'>
                                <div className='flex items-center'>
                                    <div className='flex items-center mr-auto font-medium'>
                                        <div>รหัสพนักงาน</div>
                                        <div className='h-[18px] w-[1px] mx-3 border-[1px] border-[#E1E1E1]'></div>
                                        <div>
                                            {values.emp_initial_characters}
                                            {values.emp_special_characters}
                                            {
                                                values.emp_year_format != 0 ?  values.emp_year_format : ''
                                            }
                                            {
                                                values.emp_month_format == 1 ?  month : ''
                                            }
                                            {
                                                values.emp_date_format == 1 ?  ('0' + date).slice(-2) : ''
                                            }
                                            {
                                                values.emp_digit_format > 1 ?  currentnumber.pad(values.emp_digit_format) : ''
                                            }

                                            </div>
                                    </div>
                                    <div className='w-[250px]'>

                                    <TextField
                                        id=""
                                        select
                                        label="รูปแบบการรันเอกสาร"
                                        fullWidth
                                        name='emp_runnumber_format'
                                        value={values.emp_runnumber_format}
                                        onChange={handleChange}
                                        disabled={formdisabled}
                                        >
                                        {documentrunformatoption.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>

                                    </div>
                                </div>

                            </div>
                            <div>
                                <div className="grid grid-cols-12 lg:gap-4 gap-2">
                                    <div className="col-span-2">
                                        <TextField  
                                            label="ตัวอักษรเริ่มต้น" 
                                            variant="outlined" 
                                            name='emp_initial_characters'
                                            value={values.emp_initial_characters}
                                            onChange={handleChange}
                                            disabled={formdisabled}
                                        />
                                    </div>
                                    <div className="col-span-2">
                                        <TextField  
                                            label="ตัวอักษรพิเศษ" 
                                            variant="outlined" 
                                            name='emp_special_characters'
                                            value={values.emp_special_characters}
                                            onChange={handleChange}
                                            disabled={formdisabled}
                                        />
                                    </div>
                                    <div className="col-span-2">
                                        <TextField
                                            id=""
                                            select
                                            label="ปี"
                                            fullWidth
                                            name='emp_year_format'
                                            value={values.emp_year_format}
                                            onChange={handleChange}
                                            disabled={formdisabled}
                                            >
                                            {yearoption.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                    <div className="col-span-2">
                                        <TextField
                                            id=""
                                            select
                                            label="เดือน"
                                            fullWidth
                                            name='emp_month_format'
                                            value={values.emp_month_format}
                                            onChange={handleChange}
                                            disabled={formdisabled}
                                            >
                                            {monthoption.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                    <div className="col-span-2">
                                        <TextField
                                            id=""
                                            select
                                            label="วัน"
                                            fullWidth
                                            name='emp_date_format'
                                            value={values.emp_date_format}
                                            onChange={handleChange}
                                            disabled={formdisabled}
                                            >
                                            {dateoption.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                    <div className="col-span-2">
                                        <TextField
                                            id=""
                                            select
                                            label="จำนวนหลัก (Digit)"
                                            fullWidth
                                            name='emp_digit_format'
                                            value={values.emp_digit_format}
                                            onChange={handleChange}
                                            disabled={formdisabled}
                                            >
                                            {digitoption.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr className='my-4' />
                        <div>
                            <div  className='mb-4'>
                                <div className='flex items-center'>
                                    <div className='flex items-center mr-auto font-medium'>
                                        <div>รหัสสมาชิก</div>
                                        <div className='h-[18px] w-[1px] mx-3 border-[1px] border-[#E1E1E1]'></div>
                                        <div>
                                            {values.member_initial_characters}
                                            {values.member_special_characters}
                                            {
                                                values.member_year_format != 0 ?  values.member_year_format : ''
                                            }
                                            {
                                                values.member_month_format == 1 ?  month : ''
                                            }
                                            {
                                                values.member_date_format == 1 ?  ('0' + date).slice(-2) : ''
                                            }
                                            {
                                                values.member_digit_format > 1 ?  currentnumber.pad(values.member_digit_format) : ''
                                            }

                                            </div>
                                    </div>
                                    <div className='w-[250px]'>

                                    <TextField
                                        id=""
                                        select
                                        label="รูปแบบการรันเอกสาร"
                                        fullWidth
                                        name='member_runnumber_format'
                                        value={values.member_runnumber_format}
                                        onChange={handleChange}
                                        disabled={formdisabled}
                                        >
                                        {documentrunformatoption.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>

                                    </div>
                                </div>

                            </div>
                            <div>
                                <div className="grid grid-cols-12 lg:gap-4 gap-2">
                                    <div className="col-span-2">
                                        <TextField  
                                            label="ตัวอักษรเริ่มต้น" 
                                            variant="outlined" 
                                            name='member_initial_characters'
                                            value={values.member_initial_characters}
                                            onChange={handleChange}
                                            disabled={formdisabled}
                                        />
                                    </div>
                                    <div className="col-span-2">
                                        <TextField  
                                            label="ตัวอักษรพิเศษ" 
                                            variant="outlined" 
                                            name='member_special_characters'
                                            value={values.member_special_characters}
                                            onChange={handleChange}
                                            disabled={formdisabled}
                                        />
                                    </div>
                                    <div className="col-span-2">
                                        <TextField
                                            id=""
                                            select
                                            label="ปี"
                                            fullWidth
                                            name='member_year_format'
                                            value={values.member_year_format}
                                            onChange={handleChange}
                                            disabled={formdisabled}
                                            >
                                            {yearoption.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                    <div className="col-span-2">
                                        <TextField
                                            id=""
                                            select
                                            label="เดือน"
                                            fullWidth
                                            name='member_month_format'
                                            value={values.member_month_format}
                                            onChange={handleChange}
                                            disabled={formdisabled}
                                            >
                                            {monthoption.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                    <div className="col-span-2">
                                        <TextField
                                            id=""
                                            select
                                            label="วัน"
                                            fullWidth
                                            name='member_date_format'
                                            value={values.member_date_format}
                                            onChange={handleChange}
                                            disabled={formdisabled}
                                            >
                                            {dateoption.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                    <div className="col-span-2">
                                        <TextField
                                            id=""
                                            select
                                            label="จำนวนหลัก (Digit)"
                                            fullWidth
                                            name='member_digit_format'
                                            value={values.member_digit_format}
                                            onChange={handleChange}
                                            disabled={formdisabled}
                                            >
                                            {digitoption.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr className='my-4' />
                        <div>
                            <div  className='mb-4'>
                                <div className='flex items-center'>
                                    <div className='flex items-center mr-auto font-medium'>
                                        <div>รหัสแอดมิน</div>
                                        <div className='h-[18px] w-[1px] mx-3 border-[1px] border-[#E1E1E1]'></div>
                                        <div>
                                            {values.admin_initial_characters}
                                            {values.admin_special_characters}
                                            {
                                                values.admin_year_format != 0 ?  values.admin_year_format : ''
                                            }
                                            {
                                                values.admin_month_format == 1 ?  month : ''
                                            }
                                            {
                                                values.admin_date_format == 1 ?  ('0' + date).slice(-2) : ''
                                            }
                                            {
                                                values.admin_digit_format > 1 ?  currentnumber.pad(values.admin_digit_format) : ''
                                            }

                                            </div>
                                    </div>
                                    <div className='w-[250px]'>

                                    <TextField
                                        id=""
                                        select
                                        label="รูปแบบการรันเอกสาร"
                                        fullWidth
                                        name='admin_runnumber_format'
                                        value={values.admin_runnumber_format}
                                        onChange={handleChange}
                                        disabled={formdisabled}
                                        >
                                        {documentrunformatoption.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>

                                    </div>
                                </div>

                            </div>
                            <div>
                                <div className="grid grid-cols-12 lg:gap-4 gap-2">
                                    <div className="col-span-2">
                                        <TextField  
                                            label="ตัวอักษรเริ่มต้น" 
                                            variant="outlined" 
                                            name='admin_initial_characters'
                                            value={values.admin_initial_characters}
                                            onChange={handleChange}
                                            disabled={formdisabled}
                                        />
                                    </div>
                                    <div className="col-span-2">
                                        <TextField  
                                            label="ตัวอักษรพิเศษ" 
                                            variant="outlined" 
                                            name='admin_special_characters'
                                            value={values.admin_special_characters}
                                            onChange={handleChange}
                                            disabled={formdisabled}
                                        />
                                    </div>
                                    <div className="col-span-2">
                                        <TextField
                                            id=""
                                            select
                                            label="ปี"
                                            fullWidth
                                            name='admin_year_format'
                                            value={values.year_format}
                                            onChange={handleChange}
                                            disabled={formdisabled}
                                            >
                                            {yearoption.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                    <div className="col-span-2">
                                        <TextField
                                            id=""
                                            select
                                            label="เดือน"
                                            fullWidth
                                            name='admin_month_format'
                                            value={values.admin_month_format}
                                            onChange={handleChange}
                                            disabled={formdisabled}
                                            >
                                            {monthoption.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                    <div className="col-span-2">
                                        <TextField
                                            id=""
                                            select
                                            label="วัน"
                                            fullWidth
                                            name='admin_date_format'
                                            value={values.admin_date_format}
                                            onChange={handleChange}
                                            disabled={formdisabled}
                                            >
                                            {dateoption.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                    <div className="col-span-2">
                                        <TextField
                                            id=""
                                            select
                                            label="จำนวนหลัก (Digit)"
                                            fullWidth
                                            name='admin_digit_format'
                                            value={values.admin_digit_format}
                                            onChange={handleChange}
                                            disabled={formdisabled}
                                            >
                                            {digitoption.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr className='my-4' />
                        <div>
                            <div  className='mb-4'>
                                <div className='flex items-center'>
                                    <div className='flex items-center mr-auto font-medium'>
                                        <div>รหัสสินค้า</div>
                                        <div className='h-[18px] w-[1px] mx-3 border-[1px] border-[#E1E1E1]'></div>
                                        <div>
                                            {values.product_initial_characters}
                                            {values.product_special_characters}
                                            {
                                                values.product_year_format != 0 ?  values.product_year_format : ''
                                            }
                                            {
                                                values.product_month_format == 1 ?  month : ''
                                            }
                                            {
                                                values.product_date_format == 1 ?  ('0' + date).slice(-2) : ''
                                            }
                                            {
                                                values.product_digit_format > 1 ?  currentnumber.pad(values.product_digit_format) : ''
                                            }

                                            </div>
                                    </div>
                                    <div className='w-[250px]'>

                                    <TextField
                                        id=""
                                        select
                                        label="รูปแบบการรันเอกสาร"
                                        fullWidth
                                        name='product_runnumber_format'
                                        value={values.product_runnumber_format}
                                        onChange={handleChange}
                                        disabled={formdisabled}
                                        >
                                        {documentrunformatoption.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>

                                    </div>
                                </div>

                            </div>
                            <div>
                                <div className="grid grid-cols-12 lg:gap-4 gap-2">
                                    <div className="col-span-2">
                                        <TextField  
                                            label="ตัวอักษรเริ่มต้น" 
                                            variant="outlined" 
                                            name='product_initial_characters'
                                            value={values.product_initial_characters}
                                            onChange={handleChange}
                                            disabled={formdisabled}
                                        />
                                    </div>
                                    <div className="col-span-2">
                                        <TextField  
                                            label="ตัวอักษรพิเศษ" 
                                            variant="outlined" 
                                            name='product_special_characters'
                                            value={values.product_special_characters}
                                            onChange={handleChange}
                                            disabled={formdisabled}
                                        />
                                    </div>
                                    <div className="col-span-2">
                                        <TextField
                                            id=""
                                            select
                                            label="ปี"
                                            fullWidth
                                            name='product_year_format'
                                            value={values.product_year_format}
                                            onChange={handleChange}
                                            disabled={formdisabled}
                                            >
                                            {yearoption.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                    <div className="col-span-2">
                                        <TextField
                                            id=""
                                            select
                                            label="เดือน"
                                            fullWidth
                                            name='product_month_format'
                                            value={values.product_month_format}
                                            onChange={handleChange}
                                            disabled={formdisabled}
                                            >
                                            {monthoption.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                    <div className="col-span-2">
                                        <TextField
                                            id=""
                                            select
                                            label="วัน"
                                            fullWidth
                                            name='product_date_format'
                                            value={values.product_date_format}
                                            onChange={handleChange}
                                            disabled={formdisabled}
                                            >
                                            {dateoption.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                    <div className="col-span-2">
                                        <TextField
                                            id=""
                                            select
                                            label="จำนวนหลัก (Digit)"
                                            fullWidth
                                            name='product_digit_format'
                                            value={values.product_digit_format}
                                            onChange={handleChange}
                                            disabled={formdisabled}
                                            >
                                            {digitoption.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='py-2'></div>
                    </div>
                </fieldset>

                <div className="text-end pb-10 pe-3">
                    {
                        formdisabled === true ? (
                            <Button type="button" className='btn-st-3 mx-3' onClick={(e) => { setFormdisabled(false) }}  >แก้ไข</Button>
                        ) : (

                            <div className="text-end pt-3 btn-group-control"  >
                                <Button type="button" className='btn-st-5 me-3' >ยกเลิก</Button>
                                <Button type="submit" className='btn-st-3 ' >บันทึก</Button>
                            </div>
                            
                        )
                    }
                        
                    
                </div>
        
            </Form>
            )}
        </Formik>
    )

}

const NumberDocument = () => {

    const schema = yup.object().shape({})

    const [formdisabled, setFormdisabled] = useState(false);
    let newDate = new Date()

    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let currentnumber = 1

    Number.prototype.pad = function(n) {
        if (n==undefined)
            n = 2;
    
        return (new Array(n).join('0') + this).slice(-n);
    }


    const [form, setForm] = useState({
        "inv_runnumber_format":"",
        "inv_initial_characters":"",
        "inv_special_characters":"",
        "inv_year_format":"",
        "inv_month_format":"",
        "inv_date_format":"",
        "inv_digit_format":"",
        "nc_runnumber_format":"",
        "nc_initial_characters":"",
        "nc_special_characters":"",
        "nc_year_format":"",
        "nc_month_format":"",
        "nc_date_format":"",
        "nc_digit_format":"",

    });
    

    useEffect(() => {
        setForm({
            "inv_runnumber_format":"นับใหม่ทุกปี",
            "inv_initial_characters":"INV",
            "inv_special_characters":"-",
            "inv_year_format":"2024",
            "inv_month_format":1,
            "inv_date_format":1,
            "inv_digit_format":4,
            "nc_runnumber_format":"นับใหม่ทุกปี",
            "nc_initial_characters":"NC",
            "nc_special_characters":"-",
            "nc_year_format":"2024",
            "nc_month_format":1,
            "nc_date_format":1,
            "nc_digit_format":4,

        });
        setFormdisabled(true)
    
    }, []);


    return (
        <Formik
            enableReinitialize
            initialValues={ form }
            validationSchema={schema}
            onSubmit={async (values) => {
                setFormdisabled(true)
                
            }}
        >
            {({
                handleSubmit,
                handleChange,
                touched,
                errors,
                values,
                setFieldValue,
                isInvalid,
                isValid

            }) => (
            <Form noValidate onSubmit={handleSubmit}>
                <fieldset >
                    <div className="card-body px-xl-4">
                        <div>
                            <div  className='mb-4'>
                                <div className='flex items-center'>
                                    <div className='flex items-center mr-auto font-medium'>
                                        <div>ใบกำกับภาษี</div>
                                        <div className='h-[18px] w-[1px] mx-3 border-[1px] border-[#E1E1E1]'></div>
                                        <div>
                                            {values.inv_initial_characters}
                                            {values.inv_special_characters}
                                            {
                                                values.inv_year_format != 0 ?  values.inv_year_format : ''
                                            }
                                            {
                                                values.inv_month_format == 1 ?  month : ''
                                            }
                                            {
                                                values.inv_date_format == 1 ?  ('0' + date).slice(-2) : ''
                                            }
                                            {
                                                values.inv_digit_format > 1 ?  currentnumber.pad(values.inv_digit_format) : ''
                                            }

                                            </div>
                                    </div>
                                    <div className='w-[250px]'>

                                    <TextField
                                        id=""
                                        select
                                        label="รูปแบบการรันเอกสาร"
                                        fullWidth
                                        name='inv_runnumber_format'
                                        value={values.inv_runnumber_format}
                                        onChange={handleChange}
                                        disabled={formdisabled}
                                        >
                                        {documentrunformatoption.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>

                                    </div>
                                </div>

                            </div>
                            <div>
                                <div className="grid grid-cols-12 lg:gap-4 gap-2">
                                    <div className="col-span-2">
                                        <TextField  
                                            label="ตัวอักษรเริ่มต้น" 
                                            variant="outlined" 
                                            name='inv_initial_characters'
                                            value={values.inv_initial_characters}
                                            onChange={handleChange}
                                            disabled={formdisabled}
                                        />
                                    </div>
                                    <div className="col-span-2">
                                        <TextField  
                                            label="ตัวอักษรพิเศษ" 
                                            variant="outlined" 
                                            name='inv_special_characters'
                                            value={values.inv_special_characters}
                                            onChange={handleChange}
                                            disabled={formdisabled}
                                        />
                                    </div>
                                    <div className="col-span-2">
                                        <TextField
                                            id=""
                                            select
                                            label="ปี"
                                            fullWidth
                                            name='inv_year_format'
                                            value={values.inv_year_format}
                                            onChange={handleChange}
                                            disabled={formdisabled}
                                            >
                                            {yearoption.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                    <div className="col-span-2">
                                        <TextField
                                            id=""
                                            select
                                            label="เดือน"
                                            fullWidth
                                            name='inv_month_format'
                                            value={values.inv_month_format}
                                            onChange={handleChange}
                                            disabled={formdisabled}
                                            >
                                            {monthoption.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                    <div className="col-span-2">
                                        <TextField
                                            id=""
                                            select
                                            label="วัน"
                                            fullWidth
                                            name='inv_date_format'
                                            value={values.inv_date_format}
                                            onChange={handleChange}
                                            disabled={formdisabled}
                                            >
                                            {dateoption.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                    <div className="col-span-2">
                                        <TextField
                                            id=""
                                            select
                                            label="จำนวนหลัก (Digit)"
                                            fullWidth
                                            name='inv_digit_format'
                                            value={values.inv_digit_format}
                                            onChange={handleChange}
                                            disabled={formdisabled}
                                            >
                                            {digitoption.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr className='my-4' />
                        <div>
                            <div  className='mb-4'>
                                <div className='flex items-center'>
                                    <div className='flex items-center mr-auto font-medium'>
                                        <div>ใบแจ้งหนี้</div>
                                        <div className='h-[18px] w-[1px] mx-3 border-[1px] border-[#E1E1E1]'></div>
                                        <div>
                                            {values.nc_initial_characters}
                                            {values.nc_special_characters}
                                            {
                                                values.nc_year_format != 0 ?  values.nc_year_format : ''
                                            }
                                            {
                                                values.nc_month_format == 1 ?  month : ''
                                            }
                                            {
                                                values.nc_date_format == 1 ?  ('0' + date).slice(-2) : ''
                                            }
                                            {
                                                values.nc_digit_format > 1 ?  currentnumber.pad(values.nc_digit_format) : ''
                                            }

                                            </div>
                                    </div>
                                    <div className='w-[250px]'>

                                    <TextField
                                        id=""
                                        select
                                        label="รูปแบบการรันเอกสาร"
                                        fullWidth
                                        name='nc_runnumber_format'
                                        value={values.nc_runnumber_format}
                                        onChange={handleChange}
                                        disabled={formdisabled}
                                        >
                                        {documentrunformatoption.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>

                                    </div>
                                </div>

                            </div>
                            <div>
                                <div className="grid grid-cols-12 lg:gap-4 gap-2">
                                    <div className="col-span-2">
                                        <TextField  
                                            label="ตัวอักษรเริ่มต้น" 
                                            variant="outlined" 
                                            name='nc_initial_characters'
                                            value={values.nc_initial_characters}
                                            onChange={handleChange}
                                            disabled={formdisabled}
                                        />
                                    </div>
                                    <div className="col-span-2">
                                        <TextField  
                                            label="ตัวอักษรพิเศษ" 
                                            variant="outlined" 
                                            name='nc_special_characters'
                                            value={values.nc_special_characters}
                                            onChange={handleChange}
                                            disabled={formdisabled}
                                        />
                                    </div>
                                    <div className="col-span-2">
                                        <TextField
                                            id=""
                                            select
                                            label="ปี"
                                            fullWidth
                                            name='nc_year_format'
                                            value={values.nc_year_format}
                                            onChange={handleChange}
                                            disabled={formdisabled}
                                            >
                                            {yearoption.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                    <div className="col-span-2">
                                        <TextField
                                            id=""
                                            select
                                            label="เดือน"
                                            fullWidth
                                            name='nc_month_format'
                                            value={values.nc_month_format}
                                            onChange={handleChange}
                                            disabled={formdisabled}
                                            >
                                            {monthoption.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                    <div className="col-span-2">
                                        <TextField
                                            id=""
                                            select
                                            label="วัน"
                                            fullWidth
                                            name='nc_date_format'
                                            value={values.nc_date_format}
                                            onChange={handleChange}
                                            disabled={formdisabled}
                                            >
                                            {dateoption.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                    <div className="col-span-2">
                                        <TextField
                                            id=""
                                            select
                                            label="จำนวนหลัก (Digit)"
                                            fullWidth
                                            name='nc_digit_format'
                                            value={values.nc_digit_format}
                                            onChange={handleChange}
                                            disabled={formdisabled}
                                            >
                                            {digitoption.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr className='my-4' />
        
                        <div className='py-2'></div>
                    </div>
                </fieldset>

                <div className="text-end pb-10 pe-3">
                    {
                        formdisabled === true ? (
                            <Button type="button" className='btn-st-3 mx-3' onClick={(e) => { setFormdisabled(false) }}  >แก้ไข</Button>
                        ) : (

                            <div className="text-end pt-3 btn-group-control"  >
                                <Button type="button" className='btn-st-5 me-3' >ยกเลิก</Button>
                                <Button type="submit" className='btn-st-3 ' >บันทึก</Button>
                            </div>
                            
                        )
                    }
                        
                    
                </div>
        
            </Form>
            )}
        </Formik>
    )

}

