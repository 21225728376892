import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { useAppDispatch } from "../../redux/store/configureStore";
import { userBusinessGroupHandler } from "../../redux/handlers";

export function RegisterBusinessVerify() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const email = location?.state?.email;

  const handleResendVerification = async () => {
    try {
      await dispatch(userBusinessGroupHandler.resendVerificationEmail(email));
    } catch (error) {
      console.error("Failed to resend verification:", error);
    }
  };
  return (
    <div className="bg-[#FFF]">
      <Container className="">
        <div className="m-0 w-100 py-sm-2 bg-white h-100vh d-flex flex-column">
          <div className="mb-auto">
            <Link to="/login" className="btn btn-back-st-1 mb-auto">
              กลับหน้า Login
            </Link>
          </div>
          <div className="mb-auto">
            <div>
              <div className="mx-auto w-[72px] h-[72px] mb-10 rounded-full bg-[#2DC76D] flex items-center justify-center">
                <EmailOutlinedIcon className="text-white !text-[32px]" />
              </div>
              <div className="text-center text-[#18191C] text-[20px] font-semibold mb-7">
                ขอบคุณสำหรับการเข้าร่วมเป็นสมาชิก Allder Cafe <br />
                โปรดยืนยันตัวตนให้เสร็จเพื่อเริ่มต้น
              </div>
              <div className="text-[#767F8C] text-center  mb-7">
                เราได้ส่งอีเมลยืนยันไปที่{" "}
                <span className="text-[#000000]">{email}</span> <br />{" "}
                เปิดอีเมลและยืนยันบัญชีของคุณ
              </div>
              <div className="text-center">
                <Button
                  className="max-w-[400px] mx-auto w-full h-[48px] border-0 bg-[#2DC76D] text-white"
                  onClick={() => {
                    handleResendVerification();
                  }}
                >
                  ไม่ได้รับอีเมล ? คลิกที่นี่เพื่อส่งอีกครั้ง
                </Button>
              </div>
            </div>
          </div>
          <div className="text-center fs-20 pb-4 mt-5 tc-3 d-flex align-items-end justify-content-center">
            <span className="me-3 ">Power By</span>
            <div className="pb-05">
              <Image src="/images/logo_allder.png" width={98} />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
