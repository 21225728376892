const domain = process.env.REACT_APP_API_URL;

export { domain };

export const apiUrl = process.env.REACT_APP_API_URL;

export const YES = "YES";
export const NO = "NO";
export const OK = "OK";
export const TOKEN = "TOKEN";
// export const VER = import.meta.env.VITE_VERSION;

export const endPoint = {
  TEST_TABLE_URL: `${apiUrl}/test-table`,
  
  // Master
  PREFIX_NAME_URL: `${apiUrl}/prefix-name`,

  // BO
  BO_URL: `${apiUrl}/bo`,

};
