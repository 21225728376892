
import React, { useState,useEffect  } from 'react';
import {NavLink} from "react-router-dom";
import DataTable from 'react-data-table-component';
import Form from 'react-bootstrap/Form';
import { utils, writeFileXLSX } from 'xlsx';
import Select from 'react-select';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Formik } from 'formik';
import * as yup from 'yup';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


import { ModalAllder } from "layouts/ModalAllder";
import { DataGrid } from '@mui/x-data-grid';



export function  Productunit() {
    const [unitdata, setUnitdata] = useState([]);

    const [modalunitShow, setModalunitShow] = useState(false);
    const [modaleditunitShow, setModaleditunitShow] = useState(false);
    const [modalunitDeleteShow, setModalunitDeleteShow] = useState(false);
    const [modalsuccessShow, setModalsuccessShow] = useState(false);
    

    const [uniteditdata, setUniteditdata] = useState({});

    const getunitData = () => {

        fetch('/json/productunit.json')
          .then((response) => response.json())
          .then((result) => setUnitdata(result) )
          .catch((error) => console.log("error", error));
      };

    useEffect(() => {
        getunitData();
    
    }, []);


    const columnsd = [
        {
          field: "unitName",
          headerName: "ชื่อหน่วยนับสินค้า",
          width: 170,
          sortable: false,
          flex: 1,
          renderCell: ({ row }) => (
            <div style={{color:"#565DFF",fontWeight:500}}>{row.unitName}</div>
          ),
        },
        
        {
          field: "unitType",
          headerName: "ประเภทหน่วยนับ",
          flex: 1,
          width: 140,
          sortable: false,
          renderCell: ({ row }) => (
            row.unitType == 1 ? <div>หน่วยนับสินค้าสำเร็จรูป</div> : <div>หน่วยนับสินค้าวัตถุดิบ</div>
          ),
        },
        {
          field: "unitStatus",
          headerName: "สถานะ",
          width: 180,
          sortable: false,
          renderCell: ({ row }) => (
            <div className={"datalist-status status"+row.unitStatus}></div>
          ),
        },
        
        {
          field: "unitID",
          headerName: "จัดการ",
          width: 250,
    
          sortable: false,
          renderCell: ({ row }) => (
            <div>
                <Button type='button' className='btn-edit-st-1 ' onClick={ () => { 
                    setUniteditdata(row);
                    setModalEdit((prev) => ({ ...prev, open: true }))
                }   
                    }></Button>
                <Button type='button' className='btn-delete-st-3 ml-2 ' onClick={ () => { 
                    handleDelete(row) 
                    
                }   
                }></Button>
            </div>
          ),
        },

    
      ];


    const [filterText, setFilterText] = useState('');
	const filteredItems = unitdata.filter(
        item => item.unitName && item.unitName.toLowerCase().includes(filterText.toLowerCase())
    );

    const downloadExcel = () => {
        const ws = utils.json_to_sheet(filteredItems);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "รายชื่อสาขา");
        writeFileXLSX(wb, "รายชื่อสาขา.xlsx"); 
    };


    const handleDelete = (row) => {
        console.log(row)
        setModalConfirmDelete((prev) => ({ ...prev, open: true }))
    }

    const [modalCreate, setModalCreate] = useState({
        open: false,
        x: 0,
        y: 0,
        width: 460,
        height: 360,
        pin: false,
        mode: "normal",
        modalname: "create",
    });

    const [modalEdit, setModalEdit] = useState({
        open: false,
        x: 0,
        y: 0,
        width: 470,
        height: 360,
        pin: false,
        mode: "normal",
        modalname: "edit",
    });

    const [modalConfirmDelete, setModalConfirmDelete] = useState({
        open: false,
        x: 0,
        y: 0,
        width: 470,
        height: 320,
        pin: false,
        mode: "normal",
        modalname: "edit",
    });

    const [modalSuccess, setModalSuccess] = useState({
        open: false,
        x: 0,
        y: 0,
        width: 470,
        height: 300,
        pin: false,
        mode: "normal",
        modalname: "edit",
    });

    return (
        <div>
            <nav  aria-label="breadcrumb" className="mt-2">
                <ol className="breadcrumb fs-16 ps-0">
                <li className="breadcrumb-item "><a href="#" className="tc-7  text-decoration-none">สินค้าและบริการ</a></li>
                <li className="breadcrumb-item active tc-1 " aria-current="page"> จัดการหน่วยนับสินค้า   </li>
                </ol>
            </nav>
            <div className="fs-24 tc-7  mb-4 lh-1">จัดการหน่วยนับสินค้า </div>

            <div className="card card-st-2">
                <div className="card-body px-0 py-4">
                    <div className='d-flex align-items-center px-4 mb-4'>
                        <button className='btn btn-export-st-1 me-auto' onClick={downloadExcel} >Export</button>
                        <div className='me-3'>
                            <input type='text' className='form-control form-st-4' placeholder='ค้นหาชื่อหน่วยนับ' onKeyUp={e => setFilterText(e.target.value)} />
                        </div>
                        <Button type='button' className='btn btn btn-st-7' 
                            onClick={() => setModalCreate((prev) => ({ ...prev, open: true }))}
                            
                        >
                        เพิ่มหน่วยนับ</Button>
                    </div>

                    <DataGrid
                    getRowId={(row) => row.unitID}
                    getRowHeight={() => "auto"}
                    rows={filteredItems}
                    columns={columnsd}
                    // autoHeight={true}
                    initialState={{
                        pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                        },
                    }}
                    pageSizeOptions={[10, 20, 30]}
                    checkboxSelection
                    disableRowSelectionOnClick
                    slotProps={{
                        pagination: { labelRowsPerPage: "จำนวนรายการต่อหน้า" },
                    }}
                    sx={{
                        fontFamily: "Sarabun",
                        fontSize: "17px",
                        ".MuiDataGrid-columnHeaderTitle": {
                        fontFamily: "Sarabun",
                        fontSize: "17px",
                        },
                        '.MuiDataGrid-cell':{
                            display:'flex',
                            alignItems:'center',
                            paddingTop:'5px',
                            paddingBottom:'5px'
                        }
                    }}
                    />

                    
                </div>

                
            </div>

            <UnitModal
                modal={modalCreate}
                setModal={setModalCreate}
            />
            <UnitEditModal
                modal={modalEdit}
                setModal={setModalEdit}
                onDelete={() => {
                    setModalConfirmDelete((prev) => ({ ...prev, open: true }))
                    setModalEdit((prev) => ({ ...prev, open: false }))
                }}
                uniteditdata={uniteditdata}
            />
            <ConfirmDeleteModal
                modal={modalConfirmDelete}
                setModal={setModalConfirmDelete}
                show={modalunitDeleteShow}
                onHide={() => setModalConfirmDelete((prev) => ({ ...prev, open: false }))}
                onConfirm={() => setModalConfirmDelete((prev) => ({ ...prev, open: false }))}
                onSuccess={() => setModalSuccess((prev) => ({ ...prev, open: true }))}
                uniteditdata={uniteditdata}
                
            />


            <SuccessDeleteModal
                modal={modalSuccess}
                setModal={setModalSuccess}
                show={modalsuccessShow}
                onHide={() => setModalSuccess((prev) => ({ ...prev, open: false }))}
            />
        </div>
    )
}

function UnitModal({modal,setModal}) {

    const schema = yup.object().shape({
        unitName: yup.string().required(),
    });


    const [unitdata, setUnitdata] = useState(
        {
            "unitName": "",
            "unitType": 1,
        }
    );

    return (

        <>
            <ModalAllder
              modal={modal}
              setModal={setModal}
              htitle={"เพิ่มหน่วยนับสินค้าใหม่"}
              hbtn={true}
            >
              <div className="py-4">
                <div className=''>
                    <Formik
                        enableReinitialize
                        initialValues={ unitdata }
                        validationSchema={schema}
                        onSubmit={async (values) => {
                            setModal((prev) => ({ ...prev, open: false }))
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            touched,
                            errors,
                            values,
                            setFieldValue,
                            isInvalid,
                            isValid

                        }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Group className="mb-3 form-st-3 pb-1">
                                <Form.Label className="mb-1">ชื่อหน่วยนับสินค้า <span className='text-danger'>*</span> </Form.Label>
                                <Form.Control
                                type="text" 
                                name="unitName" 
                                className="form-control" 
                                placeholder="ชื่อหน่วยนับสินค้า"
                                value={values.unitName}
                                onChange={handleChange}
                                isInvalid={!!errors.unitName}
                                
                                />
                            </Form.Group>

                            <div>
                                <Form.Label className="mb-2  tc-10">หน่วยนับสินค้า</Form.Label>
                                <div className="mb-3">
                                <Form.Check
                                    inline
                                    label="หน่วยนับสินค้าสำเร็จรูป"
                                    name="unitType"
                                    type="radio"
                                    id={`unitType-1`}
                                    className='form-check-st-2 tc-7'
                                    checked={values.unitType === 1}
                                    onChange={(e) => {
                                        setFieldValue("unitType", 1)
                                    }}
                                />
                                <Form.Check
                                    inline
                                    label="หน่วยนับสินค้าวัตถุดิบ"
                                    name="unitType"
                                    type="radio"
                                    id={`unitType-2`}
                                    className='form-check-st-2 tc-7'
                                    checked={values.unitType === 2}
                                    onChange={(e) => {
                                        setFieldValue("unitType", 2)
                                    }}
                                />
                                </div>
                            </div>

                            <div className="text-center pt-3 pb-3 btn-group-control" >
                                <Button type="button" className='btn-st-6 me-3 ' style={{width:'100px'}}  onClick={() => setModal((prev) => ({ ...prev, open: false }))}  >ยกเลิก</Button>
                                <Button type="submit" className='btn-st-3  ' style={{width:'100px'}}>ยืนยัน</Button>
                            </div>
                        </Form>
                        )}
                    </Formik>
                </div>
              </div>
            </ModalAllder>
        </>
    );
}

function UnitEditModal({modal,setModal,uniteditdata,onDelete}) {

    const schema = yup.object().shape({
        unitName: yup.string().required(),
    });


    return (
        <ModalAllder
            modal={modal}
            setModal={setModal}
            htitle={"แก้ไขหน่วยนับสินค้า"}
            hbtn={true}
        >
        <div className="py-4">
            <div className=''>
                <Formik
                    enableReinitialize
                    initialValues={ uniteditdata }
                    validationSchema={schema}
                    onSubmit={async (values) => {
                        setModal((prev) => ({ ...prev, open: false }))
                    }}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        touched,
                        errors,
                        values,
                        setFieldValue,
                        isInvalid,
                        isValid

                    }) => (
                    <Form noValidate onSubmit={handleSubmit}>
      
                        <Form.Group className="mb-3 form-st-3 pb-1">
                            <Form.Label className="mb-1">ชื่อหน่วยนับสินค้า <span className='text-danger'>*</span> </Form.Label>
                            <Form.Control
                            type="text" 
                            name="unitName" 
                            className="form-control" 
                            placeholder="ชื่อหน่วยนับสินค้า"
                            value={values.unitName}
                            onChange={handleChange}
                            isInvalid={!!errors.unitName}
                            
                            />
                        </Form.Group>

                        <div>
                            <Form.Label className="mb-2  tc-10">หน่วยนับสินค้า</Form.Label>
                            <div className="mb-3">
                            <Form.Check
                                inline
                                label="หน่วยนับสินค้าสำเร็จรูป"
                                name="unitType"
                                type="radio"
                                id={`unitType-1`}
                                className='form-check-st-2 tc-7'
                                checked={values.unitType === 1}
                                onChange={(e) => {
                                    setFieldValue("unitType", 1)
                                }}
                            />
                            <Form.Check
                                inline
                                label="หน่วยนับสินค้าวัตถุดิบ"
                                name="unitType"
                                type="radio"
                                id={`unitType-2`}
                                className='form-check-st-2 tc-7'
                                checked={values.unitType === 2}
                                onChange={(e) => {
                                    setFieldValue("unitType", 2)
                                }}
                            />
                            </div>
                        </div>
   
                        <div className="d-flex align-items-center pt-4 pb-3 btn-group-control" >
                            <Button type="button" className='btn-st-5 me-auto ' style={{width:'80px'}} 
                            onClick={ () => { 
                                onDelete()
                            } } >ลบ</Button>
                            <Button type="button" className='btn-st-6 me-3 ' style={{width:'100px'}}  onClick={() => setModal((prev) => ({ ...prev, open: false }))}  >ยกเลิก</Button>
                            <Button type="submit" className='btn-st-3  ' style={{width:'100px'}}>ยืนยัน</Button>
                        </div>
                    </Form>
                    )}
                </Formik>
            </div>
        </div>
        </ModalAllder>
    );
}

function ConfirmDeleteModal({modal,setModal,...props}) {


    const handleDelete = (unitID) => {
        props.onHide();
        props.onSuccess();
    }

    return (
        <ModalAllder
            modal={modal}
            setModal={setModal}
            htitle={"ยืนยันการลบ"}
            hbtn={false}
        >
            <div className="py-4">

                {
                    props.uniteditdata.productQty > 0 ? (
                        <div>
                            <div>
                                <div className='text-center fs-22 tc-7 mb-2'>ขออภัยไม่สามารถลบได้</div>
                                <div className='text-center fs-22 tc-7'>เนื่องจากมีสินค้าอยู่ในหน่วยนับ</div>
                            </div>
                            <div className="text-center pt-4 mt-3  btn-group-control" >
                                <Button type="button" className='btn-st-3 mx-3 ' style={{width:'100px'}}  onClick={props.onHide}  >ตกลง</Button>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div>
                                <div className='text-center fs-22 tc-7 mb-2'>คุณต้องการลบ</div>
                                <div className='text-center fs-22 tc-7'>จัดการหน่วยนับนี้ใช่หรือไม่</div>
                            </div>

                            <div className="text-center pt-4 mt-3  btn-group-control" >
                                <Button type="button" className='btn-st-3 mx-3  ' style={{width:'100px'}}  onClick={ () => handleDelete(props.uniteditdata.unitID)  }>ใช่</Button>
                                <Button type="button" className='btn-st-6 mx-3 ' style={{width:'100px'}}  onClick={props.onHide}  >ไม่ใช่</Button>
                            </div>
                        </div>
                    )
                }
   
            </div>
            
        </ModalAllder>
    );
}

function SuccessDeleteModal({modal,setModal,...props}) {


    return (
        <ModalAllder
            modal={modal}
            setModal={setModal}
            htitle={""}
            hbtn={false}
        >
            <div className="py-4">
                <div className='text-center mb-4'>
                    <svg className="mx-auto" width="84" height="83" viewBox="0 0 84 83" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="42" cy="41.5" r="41.5" fill="#2DC76D"/>
                    <path d="M56.0123 30.9902L36.7444 50.2581L27.9863 41.5" stroke="white" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>

                </div>
                <div className='text-center fs-22 tc-7'>ลบหน่วยนับสำเร็จ</div>
            </div>
            
        </ModalAllder>
    );
}

const Checkbox = React.forwardRef(({ onClick, ...rest }, ref) =>
{
 return(
     <>
         <div className="form-check form-check-st-3 " style={{ backgroundColor: '' }}>
             <input 
                 type="checkbox"
                 className="form-check-input"
                 style={{ height: '18px', width: '18px' }}
                 ref={ref}
                 onClick={ onClick }
                 {...rest}
             />
             <label className="form-check-label" id="booty-check" />
         </div>
     </>
 )
})

function getCurrentDate(separator='',dateb=new Date()){

    let newDate = dateb;
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${date}${separator}${month<10?`0${month}`:`${month}`}${separator}${year}`
}