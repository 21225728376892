

import React, { useState } from 'react';
import {Link,useNavigate,Outlet} from "react-router-dom";


import '../styles/sidemenu.css';
import '../styles/style.css';



import { Header } from "./header";
import { Navcustom } from "./nav";


export function  Layout({selectbranchID,setSelectBranchID}) {

    
    return (
        <div className="page">
          <Header selectbranchID={selectbranchID}  />
          <div className="main-content app-content">
            <Navcustom setSelectBranchID={setSelectBranchID} selectbranchID={selectbranchID} />
            <div className="container-fluid">
                <Outlet />
            </div>
            
          </div>
        </div>
    
    );
}