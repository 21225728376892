import Image from 'react-bootstrap/Image';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import React, { useState,useEffect } from 'react';
import {Link,useNavigate} from "react-router-dom";
import InfoIcon from '@mui/icons-material/Info';
import Select, { StylesConfig } from 'react-select';

import { ThailandAddressTypeahead, ThailandAddressValue } from 'react-thailand-address-typeahead'

import { Formik } from 'formik';
import * as yup from 'yup';

export function  BusinessSetting() {

    const [tab, setTab] = useState(1);

    const [businessData, setBusinessData] = useState(
        {
            "businessimage": '', 
            "url_business_name": '', 
            "taxIdentificationNumber": '',  
            "businesstype": '',
            "prefix": '',
            "businessName":'',
            "businessLastname":'',
            "subdistrict":'' ,
            "district":'' ,
            "province":'' ,
            "postalcode":'' ,
            "restaurantType":'' ,
            "productType":'',
            "firstname":'' ,
            "lastname":'',
            "address":'',
        }
    );

    const [contactData, setContactData] = useState(
        {
            "telephonenumber": "", 
            "mobilenumber": "", 
            "email": "", 
            "website": "",
            "facebook": "",
            "line":"",
            "googlemap":"" 
        }
    );

    const [saleData, setSaleData] = useState(
        {
            "currency": "", 
            "vatcalc": "", 
            "servicecharge": "",
            "checkvat":0,
            "checkservicecharge":0,
            "vat":""
        }
    );

    const [mode, setMode] = useState('create');
    const [formdisabled, setFormdisabled] = useState(false);

    const [address, setAddress] = useState(
        ThailandAddressValue.fromDatasourceItem({
          d: "",
          p: "",
          po: "",
          s: "",
        })
      );



    const getbusinessData = () => {

        fetch('/json/setting.json')
          .then((response) => response.json())
          .then((result) => {
            
            if(result.business){
                setBusinessData(result.business);
                setAddress({
                    "district":result.business.district,
                    "subdistrict":result.business.subdistrict,
                    "province":result.business.province,
                    "postalCode":result.business.postalcode,

                })

                setContactData(result.contact);
                setSaleData(result.sale);
                setMode('edit');
                setFormdisabled(true)
                
            }
            
          })
          .catch((error) => console.log("error", error));
      };
    
    
    useEffect(() => {
        getbusinessData();
    
    }, []);

    return (
        <div>
            <nav  aria-label="breadcrumb" className="mt-2">
                <ol className="breadcrumb fs-16 ps-0">
                <li className="breadcrumb-item "><a href="#" className="tc-7  text-decoration-none">ตั้งค่าธุรกิจ</a></li>
                <li className="breadcrumb-item active tc-1 " aria-current="page"> ข้อมูลธุรกิจ   </li>
                </ol>
            </nav>
            <div className="fs-24 tc-7  mb-4 lh-1">ข้อมูลธุรกิจ </div>
            <div className="card card-st-1">
                <div className="card-header">
                    {
                        mode == 'edit' ? (
                            <nav>
                                <div className="nav nav-tabs nav-tabs-st-2" id="nav-tab" role="tablist">
                                <button className={ tab == 1 ? ( 'active nav-link') : ('nav-link ') }   type="button"  onClick={ e => { setTab(1) } }> <span className="i-document me-2"></span> ข้อมูลธุรกิจ</button>
                                <button className={ tab == 2 ? ( 'active nav-link') : ('nav-link ') } type="button" onClick={ e => { setTab(2) } }><span className="i-location me-2"></span> ข้อมูลติดต่อ</button>
                                <button className={ tab == 3 ? ( 'active nav-link') : ('nav-link ') } type="button" onClick={ e => { setTab(3) } }><span className="i-setting me-2"></span> ตั้งค่าการขาย</button>
                                </div>
                            </nav>
                        ) : (
                            <nav>
                                <div className="nav nav-tabs nav-tabs-st-2" id="nav-tab" role="tablist">
                                <button className={ tab == 1 ? ( 'active nav-link') : ('nav-link ') }   type="button"  > <span className="i-document me-2"></span> ข้อมูลธุรกิจ</button>
                                <button className={ tab == 2 ? ( 'active nav-link') : ('nav-link ') } type="button" ><span className="i-location me-2"></span> ข้อมูลติดต่อ</button>
                                <button className={ tab == 3 ? ( 'active nav-link') : ('nav-link ') } type="button"><span className="i-setting me-2"></span> ตั้งค่าการขาย</button>
                                </div>
                            </nav>
                        )
                    }
                    
                </div>
                {
                    tab == 1 ? (
                        <Business address={address}  setAddress={setAddress} businessData={businessData} setBusinessData={setBusinessData} mode={mode} setTab={setTab} formdisabled={formdisabled} setFormdisabled={setFormdisabled}  />
                    ) : (
                        tab == 2 ? (
                            <Contact contactData={contactData} setContactData={setContactData} mode={mode} setTab={setTab} formdisabled={formdisabled} setFormdisabled={setFormdisabled} />
                        ) : (
                            <Sale contactData={contactData} businessData={businessData} saleData={saleData} setSaleData={setSaleData} mode={mode} setTab={setTab} formdisabled={formdisabled} setFormdisabled={setFormdisabled} />
                        )
                    )
                }
                
            </div>
        </div>
    )
}

const Business = (props) => {
    
    const schema = yup.object().shape({
        taxIdentificationNumber: yup.string().required(),
        url_business_name: yup.string().required(),
        businessName: yup.string().required(),
        businessLastname: yup.string().when('businesstype', {
            is: (v) => v == '1' || v == '3' || v == '4' || v == '6',
            then: (schema) => schema.required()
        }),
        businesstype: yup.string().required(),
        prefix: yup.string().when('businesstype', {
            is: (v) => v != '11',
            then: (schema) => schema.required()
        }),
        address: yup.string().required(),
        subdistrict: yup.string().required(),
        district: yup.string().required(),
        province: yup.string().required(),
        postalcode: yup.string().required(),
        restaurantType: yup.string().required(),
        productType: yup.string().required()
    });

    
    
    const [businessty, setBusinessty] = useState(null);
    const [flagDisplayLastName, setFlagDisplayLastName] = useState(false);
    const optionsBusinesstype= [
        { value: '1', label: 'บุลคลธรรมดา', suffix: '' },
        { value: '2', label: 'ร้านค้า', suffix: '' },
        { value: '3', label: 'บริษัทจำกัด', suffix: 'จำกัด' },
        { value: '4', label: 'บริษัทจำกัดมหาชน', suffix: 'จำกัดมหาชน' },
        { value: '5', label: 'ห้างหุ้นส่วนสามัญ', suffix: '' },
        { value: '6', label: 'ห้างหุ้นส่วนจำกัด', suffix: 'จำกัด' },
        { value: '7', label: 'มูลนิธิ', suffix: '' },
        { value: '8', label: 'สมาคม', suffix: '' },
        { value: '9', label: 'กิจการร่วมค้า', suffix: '' },
        { value: '10', label: 'คณะบุลคล', suffix: '' },
        { value: '11', label: 'อื่นๆ', suffix: '' }
    ];

    const optionsproductType= [
        { value: '1', label: 'อาหารไทย' },
        { value: '2', label: 'อาหารญี่ปุ่น' },
        { value: '3', label: 'อาหารตะวันตก' },
        { value: '4', label: 'อาหารจีน' },
        { value: '5', label: 'อาหารเกาหลี' },
        { value: '6', label: 'ก๋วยเตี๋ยว' },
        { value: '7', label: 'ขนมหวาน' },
        { value: '8', label: 'อาหารว่าง' },
        { value: '9', label: 'ฟาสฟูต์ฟู้ด' },
        { value: '10', label: 'เบเกอรี' },
        { value: '11', label: 'ร้านกาแฟ' },
        { value: '12', label: 'อื่นๆ' }
    ];

    const optionsrestaurantType= [
        { value: '1', label: 'Cafe ( ร้านกาแฟ / เบเกอร์รี่ )' },
        { value: '2', label: 'Buffet (ร้านบุฟเฟ่ต์)' },
        { value: '3', label: 'Fine Dining' },
        { value: '4', label: 'โอมากาเสะ' },
        { value: '5', label: 'A La Carte' },
        { value: '6', label: 'ร้านอาหารจานด่วน' },
        { value: '7', label: 'ฟาสฟูต์ฟู้ด' },
        { value: '8', label: 'ร้านอาหารจานเดียว' },
        { value: '9', label: 'ร้านอาหารทั่วไป' },
        { value: '10', label: 'รถเข็น' },
        { value: '11', label: 'อื่นๆ' }
    ];

    const prefixType= [
        { value: '1', label: 'นาย' },
        { value: '2', label: 'นาง' },
        { value: '3', label: 'นางสาว' },
        { value: '4', label: 'ไม่ระบุ' }
    ];

    function getBusinessNameLabel(val){
        let result = 'ชื่อ';
        if(val=='1'){
            result = 'ชื่อจริง';
        }

        return result;
    }

    function getBusinessLastNameLabel(val){
        let result = 'คำลงท้าย';
        if(val=='1'){
            result = 'นามสกุล';
        }

        return result;
    }

    function disabledBusinessLastName(val){
        let result = false;
        if(val=='3' || val=='4' || val=='6'){
            result = true;
        }

        return result;
    }

    function displayBusinessLastName(val){
        let result = false;
        if(val=='1' || val=='3' || val=='4' || val=='6'){
            result = true;
        }

        return result;
    }

    return (
        
        <Formik
            enableReinitialize
            initialValues={ props.businessData }
            validationSchema={schema}
            onSubmit={async (values) => {
                props.setBusinessData((values))
                console.log(props.businessData);
                if(values.businesstype=='2'||values.businesstype=='5'||values.businesstype=='7'||values.businesstype=='8'||values.businesstype=='9'||values.businesstype=='10'||values.businesstype=='11'){
                    props.businessData.businessLastname = '';
                }
                if(values.businesstype=='11'){
                    props.businessData.prefix = '';
                }
                if(props.mode == 'create'){
                    props.setTab(2)
                }else{
                    // update
                }
                
            }}
        >
            {({
                handleSubmit,
                handleChange,
                touched,
                errors,
                values,
                setFieldValue,
                isInvalid,
                isValid

            }) => (
            <Form noValidate onSubmit={handleSubmit}>
                <fieldset disabled={props.formdisabled}>
                    <div className="card-body px-xl-4">
                        <div >
                            <Imageupload setFieldValue={setFieldValue} values={values} />
                            <div className="pt-3">
                                <div className="row gx-xl-4">
                                    <div className="col-xl-4">
                                        <Form.Group className="mb-2 form-st-3 pb-1">
                                            <Form.Label className="mb-1">เลขที่ประจำตัวผู้เสียภาษี</Form.Label>
                                            <Form.Control
                                            type="text" 
                                            name="taxIdentificationNumber" 
                                            className="form-control" 
                                            placeholder="เลขที่ประจำตัวผู้เสียภาษี"
                                            value={values.taxIdentificationNumber}
                                            onChange={handleChange}
                                            isInvalid={!!errors.taxIdentificationNumber}
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                  event.preventDefault();
                                                }
                                            }}
                                            maxLength={13}
                                            />
                                        </Form.Group>
                                    </div>  
                                    <div className="col-xl-4">
                                        <Form.Group className="mb-2 form-st-3 pb-1">
                                            <Form.Label className="mb-1">ชื่อ URL ธุรกิจของคุณ</Form.Label>
                                            <Form.Control
                                                type="text" 
                                                name="url_name" 
                                                className="form-control" 
                                                placeholder="กรอก Url ของคุณ"
                                                value={values.url_business_name}
                                                onChange={handleChange}
                                                isInvalid={!!errors.url_business_name}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col-xl-4 align-self-center">
                                        <div className='bg-[#D7D9FF] py-1 px-2 text-[#061126] text-[12px] flex items-center  rounded font-semibold'>
                                            <InfoIcon className='text-[#2D3CED] text-[12px] mr-2' /> การเปลี่ยน มีผลทำให้ URL การเข้าเว็บไซต์เปลี่ยนไป
                                        </div>
                                    </div>
                                    <div className="col-xl-4">
                                        <Form.Group className="mb-2 form-st-3 pb-1">
                                            <Form.Label className="mb-1">ประเภทกิจการ</Form.Label>
                                            <Select as="select"
                                                name="businesstype"
                                                onChange={(e) => {
                                                    setFieldValue("businesstype", e.value);setBusinessty(e.value);
                                                    const item = optionsBusinesstype.find(
                                                        item => item.value === e.value
                                                    );
                                                    let prefix = '';
                                                    let suffix = '';
                                                    if(item){
                                                        if(e.value=='1'||e.value=='11'){
                                                            prefix = '';
                                                        }else{
                                                            prefix = item.label;
                                                        }
                                                        suffix = item.suffix;
                                                    }
                                                    setFieldValue('prefix', prefix);
                                                    setFieldValue('businessLastname', suffix);
                                                }}
                                                className={ !!errors.businesstype ? ( 'w-100 select-st-1 isInvalid ' ) : 'w-100 select-st-1'  }
                                                options={optionsBusinesstype}
                                                placeholder="เลือกประเภทกิจการ"
                                                isInvalid={!!errors.businesstype}
                                                value={
                                                    optionsBusinesstype.filter(
                                                        item => item.value === values.businesstype
                                                    )
                                                }
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col-xl-8">
                                        <div className="row gx-xl-4">
                                            {
                                                values.businesstype!='11' && (
                                                    <>
                                                        <div className='col-xl-4'>
                                                            <Form.Group className="mb-2 form-st-3 pb-1">
                                                                <Form.Label className="mb-1">คำนำหน้า</Form.Label>
                                                                {
                                                                    values.businesstype=='1'?
                                                                        <Select as="select"
                                                                                name="prefix"
                                                                                onChange={(e) => {
                                                                                    setFieldValue("prefix", e.value);
                                                                                }}
                                                                                className={ !!errors.prefix ? ( 'w-100 select-st-1 isInvalid ' ) : 'w-100 select-st-1'  }
                                                                                options={prefixType}
                                                                                placeholder="เลือกคำนำหน้า"
                                                                                isInvalid={!!errors.prefix}
                                                                                value={
                                                                                    prefixType.filter(
                                                                                        item => item.value === values.prefix
                                                                                    )
                                                                                }
                                                                        />:
                                                                        <Form.Control
                                                                            type="text"
                                                                            name="prefix"
                                                                            className="form-control"
                                                                            placeholder="คำนำหน้า"
                                                                            value={values.prefix}
                                                                            onChange={handleChange}
                                                                            isValid={touched.prefix && !errors.prefix}
                                                                            isInvalid={!!errors.prefix}
                                                                            disabled
                                                                        />
                                                                }
                                                            </Form.Group>
                                                        </div>
                                                    </>
                                                )
                                            }
                                            <div className={`col-xl-${values.businesstype=='11'?'8':'4'}`}>
                                                <Form.Group className="mb-2 form-st-3 pb-1">
                                                    <Form.Label className="mb-1">
                                                        {getBusinessNameLabel(values.businesstype)}
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="businessName"
                                                        className="form-control"
                                                        placeholder={getBusinessNameLabel(values.businesstype)}
                                                        value={values.businessName}
                                                        onChange={handleChange}
                                                        isValid={touched.businessName && !errors.businessName}
                                                        isInvalid={!!errors.businessName}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-xl-4">
                                                <Form.Group className="mb-2 form-st-3 pb-1">
                                                    {
                                                        displayBusinessLastName(values.businesstype) && (
                                                            <>
                                                                <Form.Label className="mb-1">
                                                                    {getBusinessLastNameLabel(values.businesstype)}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="businessLastname"
                                                                    className="form-control"
                                                                    placeholder={getBusinessLastNameLabel(values.businesstype)}
                                                                    value={values.businessLastname}
                                                                    onChange={handleChange}
                                                                    isValid={touched.businessLastname && !errors.businessLastname}
                                                                    isInvalid={!!errors.businessLastname}
                                                                    disabled={disabledBusinessLastName(values.businesstype)}
                                                                />
                                                            </>
                                                        )
                                                    }
                                                </Form.Group>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-xl-12">
                                        <Form.Group className="mb-2 form-st-3 pb-1">
                                            <Form.Label className="mb-1">ที่อยู่</Form.Label>
                                            <Form.Control
                                                type="text" 
                                                name="address" 
                                                className="form-control" 
                                                placeholder="กรอกที่อยู่"
                                                value={values.address}
                                                onChange={handleChange}
                                                isInvalid={!!errors.address} 
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                                <ThailandAddressTypeahead
                                    value={props.address}
                                    onValueChange={(val) => { 
                                        props.setAddress(val);

                                        setFieldValue("subdistrict", val.subdistrict)
                                        setFieldValue("district", val.district)
                                        setFieldValue("province", val.province)
                                        setFieldValue("postalcode", val.postalCode)

                                        
                                    } }
                                >
                                    <div className="row gx-xl-4">
                                        <div className="col-xl-6">
                                            <Form.Group className="mb-2 form-st-3 pb-1">
                                                <Form.Label className="mb-1">แขวง/ตำบล</Form.Label>
                                                <ThailandAddressTypeahead.SubdistrictInput 
                                                type='text'
                                                className={`form-control ${values.subdistrict?'':!!errors.subdistrict?'is-invalid':''}`}
                                                placeholder="กรอก แขวง/ตำบล" 
                                                name="subdistrict" 
                                                value={values.subdistrict}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-xl-6">
                                            <Form.Group className="mb-2 form-st-3 pb-1">
                                                <Form.Label className="mb-1">อำเภอ/เขต</Form.Label>
                                                <ThailandAddressTypeahead.DistrictInput 
                                                type='text'  
                                                className={`form-control ${values.district?'':!!errors.district?'is-invalid':''}`}
                                                placeholder="กรอก อำเภอ/เขต" 
                                                name="district" 
                                                value={values.district}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-xl-6">
                                            <Form.Group className="mb-2 form-st-3 pb-1">
                                                <Form.Label className="mb-1">จังหวัด</Form.Label>
                                                <ThailandAddressTypeahead.ProvinceInput 
                                                type='text'  
                                                className={`form-control ${values.province?'':!!errors.province?'is-invalid':''}`}
                                                placeholder="กรอกจังหวัด" 
                                                name="province" 
                                                value={values.province}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-xl-6">
                                            <Form.Group className="mb-2 form-st-3 pb-1">
                                                <Form.Label className="mb-1">รหัสไปรษณีย์</Form.Label>
                                                <ThailandAddressTypeahead.PostalCodeInput 
                                                type='text'  
                                                className={`form-control ${values.postalcode?'':!!errors.postalcode?'is-invalid':''}`}
                                                placeholder="กรอกรหัสไปรษณีย์" 
                                                name="postalcode" 
                                                value={values.postalcode}
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <ThailandAddressTypeahead.Suggestion containerProps={{className: 'suggestion-container'}} optionItemProps={{className: 'suggestion-option'}} />
                                </ThailandAddressTypeahead>
                                <div className="row gx-xl-4">
                                    <div className="col-xl-6">
        
                                        <Form.Group className="mb-2 form-st-3 pb-1">
                                            <Form.Label className="mb-1">ประเภทร้านอาหาร</Form.Label>
                                            <Select as="select"
                                                name="restaurantType"
                                                onChange={(e) => {
                                                    setFieldValue("restaurantType", e.value)
                                                }}
                                                className={ !!errors.restaurantType ? ( 'w-100 select-st-1 isInvalid ' ) : 'w-100 select-st-1'  }
                                                options={optionsBusinesstype}
                                                placeholder="เลือกประเภทร้านอาหาร"
                                                value={
                                                    optionsBusinesstype.filter(
                                                        item => item.value === values.restaurantType
                                                    )
                                                }
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col-xl-6">
                                        <Form.Group className="mb-2 form-st-3 pb-1">
                                            <Form.Label className="mb-1">เลือกประเภทสินค้า</Form.Label>
                                            <Select as="select"
                                                name="productType"
                                                onChange={(e) => {
                                                    setFieldValue("productType", e.value)
                                                }}
                                                className={ !!errors.productType ? ( 'w-100 select-st-1 isInvalid ' ) : 'w-100 select-st-1'  }
                                                options={optionsproductType}
                                                placeholder="เลือกประเภทสินค้า"
                                                value={
                                                    optionsproductType.filter(
                                                        item => item.value === values.productType
                                                    )
                                                }
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                                
          
                            </div>

                        </div>

                    </div>
                </fieldset>
                <div className="text-end pb-4 pe-3">
                    {
                        props.formdisabled === true ? (
                            <Button type="button" className='btn-st-3 mx-3' onClick={(e) => { props.setFormdisabled(false) }}  >แก้ไข</Button>
                        ) : (
                            props.mode == 'create' ? (
                                <div className="text-end pt-3 btn-group-control" >
                                    <Button type="button" className='btn-st-5 me-3' >ยกเลิก</Button>
                                    <Button type="submit" className='btn-st-3 ' >ถัดไป</Button>
                                </div>
                            ) : (
                                <div className="text-end pt-3 btn-group-control"  >
                                    <Button type="button" className='btn-st-5 me-3' >ยกเลิก</Button>
                                    <Button type="submit" className='btn-st-3 ' >บันทึก</Button>
                                </div>
                            )
                        )
                    }
                        
                    
                </div>
            </Form>
            )}
        </Formik>

    )
}

const Contact = (props) => {

    const schema = yup.object().shape({
        telephonenumber: yup.string().required(),
        mobilenumber: yup.string().required().min(10).max(10),
        email: yup.string().required().email('Invalid email'),
    });


    return (
        
        <Formik
            enableReinitialize
            initialValues={ props.contactData }
            validationSchema={schema}
            onSubmit={async (values) => {
                props.setContactData((values))
                if(props.mode == 'create'){
                    props.setTab(3)
                }else{
                    // update
                }
            }}
        >
            {({
                handleSubmit,
                handleChange,
                touched,
                errors,
                values,
                setFieldValue,
                isInvalid,
                isValid

            }) => (
            <Form noValidate onSubmit={handleSubmit}>
                <fieldset disabled={props.formdisabled}>
                <div className="card-body px-xl-4">
                    <div >
   
                        <div className="py-3">
                            <div className="row gx-xl-4">
                                <div className="col-xl-6">
                                    <Form.Group className="mb-2 form-st-3 pb-1">
                                        <Form.Label className="mb-1">เบอร์โทรศัพท์</Form.Label>
                                        <Form.Control
                                        type="text" 
                                        name="telephonenumber" 
                                        className="form-control" 
                                        placeholder="กรอกเบอร์โทรศัพท์"
                                        value={values.telephonenumber}
                                        onChange={handleChange}
                                        isInvalid={!!errors.telephonenumber}
                                        />
                                    </Form.Group>
                                </div>
                                
                                <div className="col-xl-6">
                                    <Form.Group className="mb-2 form-st-3 pb-1">
                                        <Form.Label className="mb-1">เบอร์มือถือ</Form.Label>
                                        <Form.Control
                                            type="text" 
                                            name="mobilenumber" 
                                            className="form-control" 
                                            placeholder="กรอกเบอร์มือถือ"
                                            value={values.mobilenumber}
                                            onChange={handleChange}
                                            isInvalid={!!errors.mobilenumber}
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                  event.preventDefault();
                                                }
                                            }}
                                            maxLength={10}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-xl-6">
                                    <Form.Group className="mb-2 form-st-3 pb-1">
                                        <Form.Label className="mb-1">E-mail</Form.Label>
                                        <Form.Control
                                        type="email" 
                                        name="email" 
                                        className="form-control" 
                                        placeholder="E-mail"
                                        value={values.email}
                                        onChange={handleChange}
                                        isInvalid={!!errors.email}
                                        />
                                    </Form.Group>
                                </div>
                                
                                <div className="col-xl-6">
                                    <Form.Group className="mb-2 form-st-3 pb-1">
                                        <Form.Label className="mb-1">Website</Form.Label>
                                        <Form.Control
                                            type="text" 
                                            name="website" 
                                            className="form-control" 
                                            placeholder="Website"
                                            value={values.website}
                                            onChange={handleChange}
                                            isInvalid={!!errors.website}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-xl-6">
                                    <Form.Group className="mb-2 form-st-3 pb-1">
                                        <Form.Label className="mb-1">Facebook</Form.Label>
                                        <Form.Control
                                        type="text" 
                                        name="facebook" 
                                        className="form-control" 
                                        placeholder="Facebook"
                                        value={values.facebook}
                                        onChange={handleChange}
                                        isInvalid={!!errors.facebook}
                                        />
                                    </Form.Group>
                                </div>
                                
                                <div className="col-xl-6">
                                    <Form.Group className="mb-2 form-st-3 pb-1">
                                        <Form.Label className="mb-1">Line</Form.Label>
                                        <Form.Control
                                            type="text" 
                                            name="line" 
                                            className="form-control" 
                                            placeholder="Line"
                                            value={values.line}
                                            onChange={handleChange}
                                            isInvalid={!!errors.line}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-xl-12">
                                    <Form.Group className="mb-2 form-st-3 pb-1">
                                        <Form.Label className="mb-1">URL Google Map</Form.Label>
                                        <Form.Control
                                        type="text" 
                                        name="googlemap" 
                                        className="form-control" 
                                        placeholder="URL Google Map"
                                        value={values.googlemap}
                                        onChange={handleChange}
                                        isInvalid={!!errors.googlemap}
                                        />
                                    </Form.Group>
                                </div>
                            

       
                            </div>
                            
                        </div>

                    </div>
                
                </div>
                </fieldset>
                <div className="text-end pb-4 pe-3">
                    {
                        props.formdisabled === true ? (
                            <Button type="button" className='btn-st-3 mx-3' onClick={(e) => { props.setFormdisabled(false) }}  >แก้ไข</Button>
                        ) : (

                            props.mode == 'create' ? (
                                <div className="text-end pt-3 btn-group-control"  >
                                    <Button type="button" className='btn-st-5 me-3' >ยกเลิก</Button>
                                    <Button type="button" className='btn-st-6 me-3' onClick={(e) => { props.setTab(1) }} >ย้อนกลับ</Button>
                                    <Button type="submit" className='btn-st-3 ' >ถัดไป</Button>
                                </div>
                            ) : (
                                <div className="text-end pt-3 btn-group-control"  >
                                    <Button type="button" className='btn-st-5 me-3' >ยกเลิก</Button>
                                    <Button type="submit" className='btn-st-3 ' >บันทึก</Button>
                                </div>
                            )
                        )
                    }
                </div>
            </Form>
            )}
        </Formik>

    )
}

const Sale = (props) => {

    const [checkvat, setCheckvat] = useState(true);
    const [checkservicecharge, setCheckservicecharge] = useState(true);

    const schema = yup.object().shape({
        currency: yup.string().required(), 
        vatcalc: yup.string().required(),
        servicecharge: yup.string().when('checkservicecharge', {
            is: (v) => v == 1,
            then: (schema) => schema.required()
        }),
        vat: yup.string().when('checkvat', {
            is: (v) => v == 1,
            then: (schema) => schema.required()
        })
    });


    const optionsCurrency= [
        { value: '1', label: 'บาท' }
    ];

    const optionsVatcalc= [
        { value: '1', label: 'รวมภาษี' },
        { value: '2', label: 'ไม่รวมภาษี' },
        { value: '3', label: 'ไม่มีภาษี' },
    ];
    
    return (
        
        <Formik
            enableReinitialize
            initialValues={ props.saleData }
            validationSchema={schema}
            onSubmit={async (values) => {
                props.setSaleData(values);
                props.saleData.checkvat = checkvat;
                props.saleData.checkservicecharge = checkservicecharge;
                props.saleData.currency = values.currency;
                props.saleData.vatcalc = values.vatcalc;
                if(props.saleData.checkservicecharge=='0'){
                    props.saleData.servicecharge = '';
                }else{
                    props.saleData.servicecharge = values.servicecharge;
                }
                if(props.saleData.checkvat=='0'){
                    props.saleData.vat = '';
                }else{
                    props.saleData.vat = values.vat;
                }
                if(props.mode == 'create'){
                    console.log(props.saleData);
                    console.log(props.businessData);
                    console.log(props.contactData);
                    const data = {
                        "bussiness_type_id": props.businessData.businesstype,
                        "restaurant_type_id": props.businessData.restaurantType,
                        "business_product_type_id": props.businessData.productType,
                        "name": props.businessData.businessName,
                        "prefix": props.businessData.prefix,
                        "suffix": props.businessData.businessLastname,
                        "img_url": '',
                        "taxpaper_id_number": props.businessData.taxIdentificationNumber,
                        "vat_code": props.businessData.vatcode,
                        "address": props.businessData.address,
                        "zipcode": props.businessData.postalcode,
                        "business_cat": "",
                        "phone_number": props.contactData.telephonenumber,
                        "mobile_number": props.contactData.mobilenumber,
                        "email": props.contactData.email,
                        "website": props.contactData.website,
                        "facebook": props.contactData.facebook,
                        "line": props.contactData.line,
                        "location": props.contactData.googlemap,
                        "currency": props.saleData.currency,
                        "service_charge": props.saleData.servicecharge,
                        "vat": props.saleData.vat,
                        "is_include_service_charge": props.saleData.checkservicecharge==1,
                        "is_include_vat": props.saleData.checkvat==1,
                        "cal_tax_solution_id": props.saleData.vatcalc,
                    };

                    const response = await fetch(`https://api.alldercafe.com/api/v1/business-info`, {
                        method: "POST",
                        mode: "no-cors", // no-cors, *cors, same-origin
                        cache: "no-cache",
                        headers: {
                            'Accept': 'application/json',
                            "Content-Type": "application/json",
                            "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2NvdW50SWQiOjEsImlhdCI6MTcwMDY4MDQ4MCwiZXhwIjoxNzAwNjg3NjgwfQ.NbuNplfAEVEM9LfRMq06GqIOxMDWyyhx-6prAQGgWSk"
                        },
                        body: JSON.stringify(data)
                    });
                    const json = response.json();
                    console.log(json);
                }else{
                    // update
                }
            }}
        >
            {({
                handleSubmit,
                handleChange,
                touched,
                errors,
                values,
                setFieldValue,
                isInvalid,
                isValid

            }) => (
            <Form noValidate onSubmit={handleSubmit} >
                <fieldset disabled={props.formdisabled}>
                    <div className="card-body px-xl-4">
                        <div >
    
                            <div className="py-3">
                                <div className="row gx-xl-4">
                                    <div className="col-xl-6">
                                        <Form.Group className="mb-2 form-st-3 pb-1">
                                            <Form.Label className="mb-1">สกุลเงิน <span className='text-danger'>*</span></Form.Label>
                                            <Select as="select"
                                                name="currency"
                                                onChange={(e) => {
                                                    setFieldValue("currency", e.value)
                                                }}
                                                className={ !!errors.currency ? ( 'w-100 select-st-1 isInvalid ' ) : 'w-100 select-st-1'  }
                                                options={optionsCurrency}
                                                placeholder="สกุลเงิน"
                                                isInvalid={!!errors.currency}
                                                value={
                                                    optionsCurrency.filter(
                                                        item => item.value === values.currency
                                                    )
                                                }
                                            />
                                        </Form.Group>
                                        
                                        <div className='d-flex align-items-center'>
                                            <Form.Check // prettier-ignore
                                                type="switch"
                                                id="custom-switch"
                                                label=""
                                                name='checkservicecharge'
                                                className='custom-switch-st-1'
                                                onChange={(e) => {
                                                    setCheckservicecharge(e.target.checked?1:0);
                                                    setFieldValue("checkservicecharge",e.target.checked?1:0);
                                                }}
                                                checked={values.checkservicecharge == 1}
           
                                            />
                                            <Form.Group className="mb-2 form-st-3 pb-1 ms-4 flex-fill percent">
                                                <Form.Label className="mb-1">ค่าบริการ (Service Charge) <span className='text-danger'>*</span></Form.Label>
                                                <Form.Control
                                                type="text" 
                                                name="servicecharge" 
                                                className="form-control " 
                                                placeholder="ค่าบริการ (Service Charge)"
                                                value={values.servicecharge}
                                                onChange={handleChange}
                                                isInvalid={!!errors.servicecharge}
                                                disabled={values.checkservicecharge == 0}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className='d-flex align-items-center'>
                                            <Form.Check // prettier-ignore
                                                type="switch"
                                                id="custom-switch2"
                                                label=""
                                                name='checkvat'
                                                className='custom-switch-st-1'
                                                onChange={(e) => {
                                                    setCheckvat(e.target.checked?1:0);
                                                    setFieldValue("checkvat",e.target.checked?1:0);
                                                }}
                                                checked={values.checkvat == 1}
                                            />
                                            <Form.Group className="mb-2 form-st-3 pb-1 ms-4 flex-fill percent">
                                                <Form.Label className="mb-1">ภาษีมูลค่าเพิ่ม (VAT) <span className='text-danger'>*</span></Form.Label>
                                                <Form.Control
                                                    type="text" 
                                                    name="vat" 
                                                    className="form-control " 
                                                    placeholder="ภาษีมูลค่าเพิ่ม (VAT)"
                                                    value={values.vat}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.vat}
                                                    disabled={values.checkvat == 0}
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                />
                                            </Form.Group>
                                        </div>

                                        <Form.Group className="mb-2 form-st-3 pb-1">
                                            <Form.Label className="mb-1">วิธีคิดภาษีมูลค่าเพิ่ม <span className='text-danger'>*</span></Form.Label>
                                            <Select as="select"
                                                name="vatcalc"
                                                onChange={(e) => {
                                                    setFieldValue("vatcalc", e.value)
                                                }}
                                                className={ !!errors.vatcalc ? ( 'w-100 select-st-1 isInvalid ' ) : 'w-100 select-st-1'  }
                                                options={optionsVatcalc}
                                                placeholder="วิธีคิดภาษีมูลค่าเพิ่ม"
                                                isInvalid={!!errors.vatcalc}
                                                value={
                                                    optionsVatcalc.filter(
                                                        item => item.value === values.vatcalc
                                                    )
                                                }
                                            />
                                        </Form.Group>

                                    </div>
                                    
                                    <div className="col-xl-6 text-center d-flex align-items-center justify-content-center">
                                        <Image src='/images/img2.png' width={143} />
                                    </div>


        
                                </div>
                                

                            </div>

                        </div>
                    
                    </div>
                </fieldset>
                <div className="text-end pb-4 pe-3">
                {
                    props.formdisabled === true ? (
                        <Button type="button" className='btn-st-3 mx-3' onClick={(e) => { props.setFormdisabled(false) }}  >แก้ไข</Button>
                    ) : (
                        props.mode == 'create' ? (
                            <div className="text-end pt-3 btn-group-control"  >
                                <Button type="button" className='btn-st-5 me-3' >ยกเลิก</Button>
                                <Button type="button" className='btn-st-6 me-3' onClick={(e) => { props.setTab(2) }} >ย้อนกลับ</Button>
                                <Button type="submit" className='btn-st-3 ' >บันทึก</Button>
                            </div>
                        ) : (
                            <div className="text-end pt-3 btn-group-control"  >
                                <Button type="button" className='btn-st-5 me-3' >ยกเลิก</Button>
                                <Button type="submit" className='btn-st-3 ' >บันทึก</Button>
                            </div>
                        )
                    )
                }
                </div>
            </Form>
            )}
        </Formik>

    )
}

const Imageupload = (props) => {

    const [imgData, setImgData] = useState(false);
    
    const handlePreviewimage = e => {
        if (e.target.files[0]) {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
            setImgData(reader.result);
            props.setFieldValue("businessimage", reader.result)

            });
            reader.readAsDataURL(e.target.files[0]);
        }
    }



    return (
        <div className="d-flex align-items-center mb-4 mt-3 ">
            <div className="bx-upload">
                {
                    props.values.businessimage ? (
                        <img src={props.values.businessimage} />
                    ) : (
                        <div className='preimg'></div>
                    )
                }
                
            </div>
            <input type="file" name="" className="fileUpload hide-input" id='fileUpload' accept="image/*" onChange={ (e) => { handlePreviewimage(e); } } />

            <div className="flex-fill ms-3">
                <div className="mb-3">
                    <button type='button' className="btn btn-st-4 btn-upload me-3" onClick={ (e) => document.getElementById('fileUpload').click() }>อัปโหลดรูปภาพ</button>
                    <button type='button' className="btn btn-st-2 btn-remove-image" onClick={ (e) => { setImgData(false);props.setFieldValue("businessimage", '') } } >ลบ</button>
                </div>
                <div className="tc-7 fs-12">อัพโหลดไฟล์ JPG, GIF or PNG. ขนาดไม่เกิน 800K</div>
            </div>
        </div>
    )

}
