import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as yup from "yup";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import { setBusinessFormBasic } from "../../redux/slices/business.slice";
import {
  getEntityTypes,
  getAllPrefixName,
} from "../../redux/handlers/master.handler";

export function BusinessFormBasic() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const basicFormData = useSelector(
    (state) => state.business.businessForm.basic
  );
  const entityTypes = useSelector((state) => state.master.entityTypes);
  const prefixNames = useSelector((state) => state.master.prefixNames);

  useEffect(() => {
    dispatch(getEntityTypes());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllPrefixName(basicFormData.type));
  }, [dispatch, basicFormData?.type]);

  const schema = yup.object({
    cardNumber: yup.string().required("กรุณากรอกเลขประจำตัวผู้เสียภาษี"),
    type: yup.string().required("กรุณาเลือกประเภท"),
    prefix_name_id: yup.string().required("กรุณาเลือกคำนำหน้า"),
    company_name: yup.string().when("type", {
      is: (val) => val === "JURISTIC",
      then: () => yup.string().required("กรุณากรอกชื่อกิจการ"),
      otherwise: () => yup.string(),
    }),
    first_name: yup.string().when("type", {
      is: (val) => val === "NATURAL",
      then: () => yup.string().required("กรุณากรอกชื่อ"),
      otherwise: () => yup.string(),
    }),
    last_name: yup.string().when("type", {
      is: (val) => val === "NATURAL",
      then: () => yup.string().required("กรุณากรอกนามสกุล"),
      otherwise: () => yup.string(),
    }),
  });

  const initialValues = {
    cardNumber: basicFormData?.cardNumber || "",
    type: basicFormData?.type || "JURISTIC",
    prefix_name_id: basicFormData?.prefix_name_id || "",
    business_type: basicFormData?.business_type || "",
    company_name: basicFormData?.company_name || "",
    first_name: basicFormData?.first_name || "",
    last_name: basicFormData?.last_name || "",
  };

  const prefixNameOptions =
    prefixNames?.map((prefix) => ({
      value: prefix.id,
      label: prefix.name,
    })) || [];

  return (
    <div className="bg-[#FFF]">
      <Container>
        <div className="m-0 w-100 py-sm-2 bg-white h-100vh d-flex flex-column">
          <div className="mb-auto">
            <Link to="/business" className="btn btn-back-st-1 mb-auto">
              ย้อนกลับ
            </Link>
          </div>
          <div className="mb-auto">
            <Formik
              initialValues={initialValues}
              validationSchema={schema}
              enableReinitialize
              onSubmit={(values) => {
                dispatch(setBusinessFormBasic(values));
                navigate("/business/create/info");
              }}
            >
              {({
                handleSubmit,
                handleChange,
                touched,
                errors,
                values,
                setFieldValue,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Card className="card-register-st-1 !h-auto mb-4">
                    <Card.Body>
                      <div className="text-start pt-4 fs-20 fw-5 mb-4 pb-2">
                        สร้างข้อมูลกิจการ
                      </div>

                      <Form.Group className="mb-3 form-st-2 pb-1">
                        <Form.Label className="mb-1">
                          เลขที่ประจำตัวผู้เสียภาษี{" "}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="cardNumber"
                          className="form-control"
                          placeholder="เลขที่ประจำตัวผู้เสียภาษี"
                          value={values.cardNumber}
                          onChange={handleChange}
                          isInvalid={touched.cardNumber && !!errors.cardNumber}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          maxLength={13}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.cardNumber}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <div className="flex items-center my-4">
                        <div className="text-start fw-5 mb-3 mr-4">
                          สร้างข้อมูลกิจการ{" "}
                          <span className="text-danger">*</span>
                        </div>
                        <div className="mb-3">
                          {entityTypes?.map((entityType) => (
                            <Form.Check
                              key={entityType.id}
                              inline
                              label={entityType.name}
                              name="type"
                              type="radio"
                              id={`entity-type-${entityType.id}`}
                              className="form-check-st-2 tc-7"
                              checked={values.type === entityType.code}
                              onChange={() => {
                                setFieldValue("type", entityType.code);
                                setFieldValue("prefix_name_id", "");
                                if (entityType.code === "JURISTIC") {
                                  setFieldValue("first_name", "");
                                  setFieldValue("last_name", "");
                                } else {
                                  setFieldValue("company_name", "");
                                }
                                dispatch(getAllPrefixName(entityType.code));
                              }}
                            />
                          ))}
                        </div>
                      </div>
                      <Form.Group className="mb-4 form-st-2 pb-1">
                        <Form.Label className="mb-1">
                          {values.type == "JURISTIC"
                            ? "ประเภทกิจการ"
                            : "คำนำหน้า"}{" "}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Select
                          name="prefix_name_id"
                          value={prefixNameOptions.find(
                            (option) => option.value === values.prefix_name_id
                          )}
                          options={prefixNameOptions}
                          onChange={(e) => {
                            setFieldValue("prefix_name_id", e.value);
                          }}
                          placeholder="เลือกคำนำหน้า"
                          isInvalid={
                            touched.prefix_name_id && !!errors.prefix_name_id
                          }
                        />
                        {touched.prefix_name_id && errors.prefix_name_id && (
                          <div className="invalid-feedback d-block">
                            {errors.prefix_name_id}
                          </div>
                        )}
                      </Form.Group>
                      {values.type === "NATURAL" ? (
                        <>
                          <Form.Group className="mb-4 form-st-2 pb-1">
                            <Form.Label className="mb-1">
                              ชื่อ <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="first_name"
                              placeholder="ระบุชื่อ"
                              value={values.first_name}
                              onChange={handleChange}
                              isInvalid={
                                touched.first_name && !!errors.first_name
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.first_name}
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group className="mb-4 form-st-2 pb-1">
                            <Form.Label className="mb-1">
                              นามสกุล <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="last_name"
                              placeholder="ระบุชื่อนามสกุล"
                              value={values.last_name}
                              onChange={handleChange}
                              isInvalid={
                                touched.last_name && !!errors.last_name
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.last_name}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </>
                      ) : (
                        <>
                          <Form.Group className="mb-4 form-st-2 pb-1">
                            <Form.Label className="mb-1">
                              ชื่อกิจการ <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="company_name"
                              placeholder="ระบุชื่อกิจการ"
                              value={values.company_name}
                              onChange={handleChange}
                              isInvalid={
                                touched.company_name && !!errors.company_name
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.company_name}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </>
                      )}
                    </Card.Body>
                  </Card>
                  <div className="text-center py-2">
                    <Button type="submit" className="btn-st-1 mx-3">
                      ถัดไป
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <div className="text-center fs-20 pb-4 mt-5 tc-3 d-flex align-items-end justify-content-center">
            <span className="me-3">Power By</span>
            <div className="pb-05">
              <Image src="/images/logo_allder.png" width={98} />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
