import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import React, { useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as yup from "yup";
import Card from "react-bootstrap/Card";
import Select from "react-select";
import InfoIcon from "@mui/icons-material/Info";
import { debounce } from "lodash";
import {
  setBusinessFormDetails,
  resetBusinessForm,
} from "../../redux/slices/business.slice";
import { businessHandler } from "../../redux/handlers";
import {
  getShopProductCategories,
  getRestaurantTypes,
} from "../../redux/handlers/master.handler";
import { OK } from "../../constants/server/api";
import Form from "react-bootstrap/Form";

export function BusinessFormGeneral() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formData = useSelector((state) => state.business.businessForm);
  const entityTypes = useSelector((state) => state.master.entityTypes);
  const shopProductCategories = useSelector(
    (state) => state.master.shopProductCategories
  );
  const restaurantTypes = useSelector((state) => state.master.restaurantTypes);

  useEffect(() => {
    dispatch(getShopProductCategories());
    dispatch(getRestaurantTypes());
  }, [dispatch]);

  const debouncedTrimHyphen = useCallback(
    debounce((currentValue, setFieldValue) => {
      if (currentValue.endsWith("-")) {
        setFieldValue("slug", currentValue.replace(/-+$/g, ""));
      }
    }, 3000),
    []
  );

  const schema = yup.object().shape({
    restaurantTypeId: yup.number().required("กรุณาเลือกประเภทร้านอาหาร"),
    shopProductCategoryTypeId: yup.number().required("กรุณาเลือกประเภทสินค้า"),
    slug: yup
      .string()
      .required("กรุณากรอกลิงค์กิจการ")
      .min(6, "ลิงค์กิจการต้องมีความยาวอย่างน้อย 6 ตัวอักษร")
      .max(25, "ลิงค์กิจการต้องมีความยาวไม่เกิน 25 ตัวอักษร"),
  });

  const handleSubmit = async (values) => {
    try {
      const basicData = formData?.basic;
      const entityType = entityTypes.find(
        (type) => type.code === basicData.type
      );

      if (!basicData) {
        navigate("/business/create");
        return;
      }

      const requiredFields =
        basicData.type === "JURISTIC"
          ? ["cardNumber", "company_name"]
          : ["cardNumber", "first_name", "last_name"];

      const missingFields = requiredFields.filter((field) => !basicData[field]);
      if (missingFields.length > 0) {
        navigate("/business/create");
        return;
      }
      const businessData = {
        entityTypeId: entityType.id,
        cardNumber: basicData.cardNumber,
        companyName: basicData.company_name,
        firstName: basicData.first_name,
        lastName: basicData.last_name,
        prefixNameId: basicData.prefix_name_id,
        restaurantTypeId: Number(values.restaurantTypeId),
        shopProductCategoryTypeId: Number(values.shopProductCategoryTypeId),
        slug: convertToSlug(values.slug),
      };
      const res = await dispatch(businessHandler.createBusiness(businessData));
      if (res == OK) {
        dispatch(resetBusinessForm());
        navigate("/business");
      }
    } catch (error) {
      console.error("Failed to create business:", error);
    }
  };

  const initialValues = {
    restaurantTypeId: formData?.details?.restaurantTypeId || "",
    shopProductCategoryTypeId:
      formData?.details?.shopProductCategoryTypeId || "",
    slug: formData?.details?.slug || "",
  };

  const validatePreviewSlug = (value) => {
    const pattern = /^[a-z0-9\u0E00-\u0E7F]+(?:-[a-z0-9\u0E00-\u0E7F]+)*-?$/;
    return pattern.test(value);
  };

  const validateSlug = (value) => {
    const pattern = /^[a-zA-Z0-9\u0E00-\u0E7F\s]+$/;
    return pattern.test(value);
  };

  const formatSlug = (value) => {
    return value;
  };

  const convertToSlug = (value) => {
    return value
      .trim()
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^a-z0-9\u0E00-\u0E7F-]/g, "")
      .replace(/-+/g, "-")
      .replace(/^-+/g, "")
      .replace(/-+$/g, "");
  };

  const restaurantTypeOptions =
    restaurantTypes?.map((type) => ({
      value: type.id,
      label: type.name,
    })) || [];

  const productTypeOptions =
    shopProductCategories?.map((category) => ({
      value: category.id,
      label: category.name,
    })) || [];

  return (
    <div className="bg-[#FFF]">
      <Container>
        <div className="m-0 w-100 py-sm-2 bg-white h-100vh d-flex flex-column">
          <div className="mb-auto">
            <Formik
              initialValues={initialValues}
              validationSchema={schema}
              enableReinitialize
              onSubmit={handleSubmit}
            >
              {({
                handleSubmit,
                handleChange,
                touched,
                errors,
                values,
                setFieldValue,
              }) => (
                <>
                  <Link
                    to="/business/create"
                    className="btn btn-back-st-1 mb-auto"
                    onClick={() => {
                      dispatch(
                        setBusinessFormDetails({
                          restaurantTypeId: Number(values.restaurantTypeId),
                          shopProductCategoryTypeId: Number(
                            values.shopProductCategoryTypeId
                          ),
                          slug: values.slug,
                        })
                      );
                    }}
                  >
                    ย้อนกลับ
                  </Link>
                  <Form noValidate onSubmit={handleSubmit}>
                    <Card className="card-register-st-1 !h-auto mb-4">
                      <Card.Body>
                        <div className="text-start pt-4 fs-20 fw-5 mb-4 pb-2">
                          สร้างข้อมูลกิจการ
                        </div>

                        <div className="mr-2 mb-4">
                          ประเภทร้านอาหาร <span className="text-danger">*</span>
                        </div>
                        <div className="mb-3 form-st-2 pb-1">
                          <Form.Label className="mb-1">
                            ประเภทร้านอาหาร
                            <span className="text-danger">*</span>
                          </Form.Label>
                          <Select
                            name="restaurantTypeId"
                            value={restaurantTypeOptions.find(
                              (option) =>
                                option.value === values.restaurantTypeId
                            )}
                            onChange={(option) => {
                              setFieldValue(
                                "restaurantTypeId",
                                option ? option.value : null
                              );
                            }}
                            className={`w-100 select-st-1 ${
                              touched.restaurantTypeId &&
                              errors.restaurantTypeId
                                ? "error"
                                : ""
                            }`}
                            options={restaurantTypeOptions}
                            placeholder="เลือกประเภทร้านอาหาร"
                            isClearable
                          />
                          {touched.restaurantTypeId &&
                            errors.restaurantTypeId && (
                              <div className="invalid-feedback d-block">
                                {errors.restaurantTypeId}
                              </div>
                            )}
                        </div>

                        <div className="mr-2 mb-4">
                          ประเภทสินค้า <span className="text-danger">*</span>
                        </div>
                        <div className="mb-4 form-st-2 pb-1">
                          <Form.Label className="mb-1">
                            รูปแบบ
                            <span className="text-danger">*</span>
                          </Form.Label>
                          <Select
                            name="shopProductCategoryTypeId"
                            value={productTypeOptions.find(
                              (option) =>
                                option.value ===
                                values.shopProductCategoryTypeId
                            )}
                            onChange={(option) => {
                              setFieldValue(
                                "shopProductCategoryTypeId",
                                option ? option.value : null
                              );
                            }}
                            className={`w-100 select-st-1 ${
                              touched.shopProductCategoryTypeId &&
                              errors.shopProductCategoryTypeId
                                ? "error"
                                : ""
                            }`}
                            options={productTypeOptions}
                            placeholder="เลือกประเภทสินค้า"
                            isClearable
                          />
                          {touched.shopProductCategoryTypeId &&
                            errors.shopProductCategoryTypeId && (
                              <div className="invalid-feedback d-block">
                                {errors.shopProductCategoryTypeId}
                              </div>
                            )}
                        </div>

                        <div className="flex items-center mb-[35px]">
                          <div className="mr-2">
                            ลิงค์กิจการ <span className="text-danger">*</span>
                          </div>
                          <div className="bg-[#D7D9FF] py-1 px-2 text-[#061126] text-[12px] flex items-center rounded font-semibold">
                            <InfoIcon className="text-[#2D3CED] text-[12px] mr-2" />
                            การเปลี่ยน มีผลทำให้ URL การเข้าเว็บไซต์เปลี่ยนไป
                          </div>
                        </div>

                        <div className="mb-4 form-st-2 pb-1">
                          <Form.Group className="mb-4 form-st-2 pb-1">
                            <Form.Label className="mb-1">
                              ลิงค์ URL ธุรกิจของคุณ ( 6 - 25 ตัวอักษร ){" "}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="slug"
                              placeholder="กรอก URL ของคุณ"
                              value={values.slug}
                              onChange={(e) => {
                                const value = e.target.value;
                                setFieldValue("slug", value);
                              }}
                              isInvalid={touched.slug && !!errors.slug}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.slug}
                            </Form.Control.Feedback>
                          </Form.Group>

                          <div className="form-text text-blue mt-1">
                            <p>
                              ตัวอย่าง URL ของคุณ :{" "}
                              <span className="text-blue-700 font-bold text-xl">
                                {validatePreviewSlug(convertToSlug(values.slug))
                                  ? convertToSlug(values.slug)
                                  : ""}
                              </span>
                            </p>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                    <div className="text-center py-2">
                      <Button type="submit" className="btn-st-1 mx-3">
                        บันทึก
                      </Button>
                    </div>
                  </Form>
                </>
              )}
            </Formik>
          </div>
          <div className="text-center fs-20 pb-4 mt-5 tc-3 d-flex align-items-end justify-content-center">
            <span className="me-3">Power By</span>
            <div className="pb-05">
              <Image src="/images/logo_allder.png" width={98} />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
