import axios from "axios";
import join from "url-join";
import { domain } from "../constants/server/api";
import {
  NETWORK_CONNECTION_MESSAGE,
  NOT_CONNECT_NETWORK_CODE,
} from "../constants/server/error";
import { createBrowserHistory } from "history";

const isAbsoluteURLRegex = /^(?:\w+:)\/\//;
const history = createBrowserHistory();

axios.interceptors.request.use(async (config) => {
  try {
    if (!isAbsoluteURLRegex.test(config.url)) {
      config.url = join(domain, config.url);
    }
    config.timeout = 10 * 1000;
    return config;
  } catch (error) {
    return Promise.reject(error);
  }
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (axios.isCancel(error)) {
      return Promise.reject({
        isCancelled: true,
      });
    }

    if (!error.response) {
      return Promise.reject({
        code: NOT_CONNECT_NETWORK_CODE,
        message: NETWORK_CONNECTION_MESSAGE,
      });
    }

    if (error.response.data.message == "Unauthorized") {
      localStorage.removeItem("accessToken");
      window.location.href = "/login";
      return;
    }

    return Promise.reject({
      code: error.response.status,
      message: error.response.data.message || "An error occurred",
    });
  }
);

export const http = axios;
