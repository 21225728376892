import React from "react";
import { useSelector } from "react-redux";
import { Loader2 } from "lucide-react";

const LoadingSpinner = () => {
  const { isLoading, error } = useSelector((state) => state.common);

  if (!isLoading) return null;

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center">
      <div className="bg-white p-3 rounded-lg shadow-lg">
        <div className="flex flex-col items-center gap-2">
          <Loader2 className="h-10 w-10 animate-spin text-primary" />
          <p className="text-gray-700 text-sm">กำลังโหลด...</p>
        </div>
      </div>
      {error && (
        <div
          className="absolute bottom-4 right-4 bg-red-100 border-l-4 border-red-500 text-red-700 p-4"
          role="alert"
        >
          <p>{error}</p>
        </div>
      )}
    </div>
  );
};

export default LoadingSpinner;
