import { createSlice } from "@reduxjs/toolkit";
import { ERROR_MESSAGES } from "../../constants/server/error";

const initialState = {
  isLoading: false,
  error: null,
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      const errorMessage = ERROR_MESSAGES[action.payload] || action.payload;
      state.error = errorMessage;
      if (errorMessage) {
        alert(errorMessage);
        state.error = null;
      }
    },
    clearError: (state) => {
      state.error = null;
    },
    resetLoadingState: () => initialState,
  },
});

export const { setLoading, setError, clearError, resetLoadingState } =
  commonSlice.actions;
export default commonSlice.reducer;
